import {useCallback, useState} from "react";

interface BridgeDropdownProps {
  options: any[];
  currentItem: any;
  onChange: (item: any) => void;
}

const BridgeDropdown: React.FC<BridgeDropdownProps> = ({options, onChange, currentItem}) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = useCallback(() => {
    setExpanded(e => !e);
  }, []);

  return (
    <div className={`bridge-form__token-dropdown ${expanded && 'bridge-form__token-dropdown_opened'}`} onClick={toggle}>
      <div className="bridge-form__token-dropdown-value-wrapper">
        <div className="bridge-form__token-dropdown-item">
          <i className="bridge-form__token-dropdown-item-icon">
            {currentItem?.icon}
          </i>
          <p className="bridge-form__token-dropdown-item-value">{currentItem?.name}</p>
        </div>
        <div className="bridge-form__token-dropdown-arrow-wrapper"></div>
      </div>
      <div className="bridge-form__token-dropdown-expand-list">
        {options.filter((option)=>option.name !==currentItem?.name).map((option, index) => (
          <div className="bridge-form__token-dropdown-item" key={index} onClick={() => onChange(option)}>
            <i className="bridge-form__token-dropdown-item-icon">
              {currentItem?.icon}
            </i>
            <p className="bridge-form__token-dropdown-item-value">{option.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
};

export default BridgeDropdown;
