import {useCallback, useMemo} from "react";
import {useMainBridgeContract, useSideBridgeContract} from "./useContract";
import {useWeb3React} from "@web3-react/core";
import {ChainId} from "../constants/chainIds";
import {BRIDGE_ADDRESS_MAP, MONEY_ADDRESS} from "../constants/addresses";
import {ApprovalState, useApproveCallback, useTokenBalance} from "./useErc20";
import {currencyToUnit, unitToCurrency} from "../functions/assets";
import {BigNumber} from "@ethersproject/bignumber";

export function useBridgeCallback(toChainId, tokenAmount) {
    const { chainId } = useWeb3React();

    const nonEthChainId: ChainId = chainId === ChainId.ROPSTEN || chainId === ChainId.MAINNET ? toChainId: chainId
    const ethChainId = chainId === ChainId.ROPSTEN  ? chainId: toChainId

    const mainBridgeAddress = BRIDGE_ADDRESS_MAP[nonEthChainId]?.MAIN_BRIDGE_ADDRESS
    const sideBridgeAddress = BRIDGE_ADDRESS_MAP[nonEthChainId]?.SIDE_BRIDGE_ADDRESS

    const moneyTokenAddress = chainId === ChainId.ROPSTEN ? MONEY_ADDRESS[ethChainId]:MONEY_ADDRESS[nonEthChainId]

    const mainBridgeContract = useMainBridgeContract(nonEthChainId);
    const sideBridgeContract = useSideBridgeContract(nonEthChainId);

    const spender: string = chainId === ChainId.ROPSTEN? mainBridgeAddress: sideBridgeAddress

    const [approvalState, approve] = useApproveCallback(moneyTokenAddress, tokenAmount, spender )

    const tokenBalance = useTokenBalance(moneyTokenAddress)

    const bridgeTokens = useCallback(async ()=>{
        const rawBnTokenAmount = currencyToUnit(18, tokenAmount)
        const bnTokenAmount =  BigNumber.from(`0x${rawBnTokenAmount.toString(16)}`)

        if (tokenBalance.gte(bnTokenAmount)) {
            if (approvalState !== ApprovalState.APPROVED) {
                await approve()
            } else if (approvalState === ApprovalState.APPROVED) {



                if (chainId === ChainId.ROPSTEN) {
                    const bridgingFree = await mainBridgeContract?.swapFee()

                    const tx = await mainBridgeContract?.swapETH2Side(moneyTokenAddress, bnTokenAmount, {
                        value: bridgingFree
                    })
                    console.log(tx)
                } else {
                    const bridgingFree = await sideBridgeContract?.swapFee()

                    const tx = await sideBridgeContract?.swapSideToMain(moneyTokenAddress, bnTokenAmount, {
                        value: bridgingFree
                    })
                    console.log(tx)
                }
            }
        } else {
            alert('INSUFFICIENT_FUNDS')
        }

    }, [toChainId, tokenAmount, approvalState, approve, tokenBalance])

    return useMemo(()=>{
        return {
            bridgeTokens,
            tokenBalance
        }
    }, [bridgeTokens, tokenBalance])
}
