import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import Modal from "./modal"
import SubscriptionForm from "./subscriptionForm";

const UnsupportedScreenPopup: React.FC = () => {
  const location = useLocation();
  const path = location.pathname;

  const [open, setOpen] = useState(false);

  const checkScreenSize = useCallback(() => {
    const width  = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (['/404', '/503'].includes(path)) {
      if (width <= 1401) {
        setOpen(true);
      } else {
        setOpen(false);
      }

      return;
    }

    if (['/', '/economy', '/economy.html', '/offering', '/offering.html', '/ido', '/ido.html'].includes(path)) {
      if (width <= 1580 && width > 441) {
        setOpen(true);
      } else {
        setOpen(false);
      }

      return;
    }

    if (width <= 1580) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [path]);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize, true);

    return () => {
      window.removeEventListener('resize', checkScreenSize, true);
    }
  }, [checkScreenSize])

  return (
    <Modal open={false} onClose={() => setOpen(false)} wrapClassName={'small-screen-popup'}>
      <div>
        <p className="popup__title">Mobile Support Is Coming Soon!</p>
        <div className="small-screen-popup__description-wrapper">
          <p className="popup__description">
            Please visit HODLVERSE on a desktop or laptop for a full experience!
          </p>
        </div>
        <div className="small-screen-popup__description-wrapper small-screen-popup__description-wrapper_second">
          <p className="popup__description">
            Sign up on our mailing list for the latest updates about the HODLVERSE metaverse.
          </p>
        </div>
      </div>
      <SubscriptionForm onClose={() => {}} />
    </Modal>
  )
};

export default UnsupportedScreenPopup;