import Header from '../../components/header'
import {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from "./checkbox";
import InputMask from "react-input-mask";
import KYCPopup from "../../components/KYCPopup";
import useScript from "../../utils/useScript";
import appConfig from "../../config/appConfig";

const address = "0x89205A3A3b2A69De6Dbf7f01ED13B2108B2c43e7";

const IDOPage: React.FC = () => {
  useScript('https://static.vouched.id/widget/vouched-2.0.0.js');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [isRecaptchaConfirmed, setIsRecaptchaConfirmed] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [kycPhone, setKYCPhone] = useState('');
  const [KYCPhoneValid, setKYCPhoneValid] = useState(false);
  const [isUSLocation, setIsUSLocation] = useState(false);
  const [showKYCPopup, setShowKYCPopup] = useState(false);

  useEffect(() => {
    document.body.classList.add('body_ido');

    fetch('https://api.ipregistry.co/?key=77yz4tqez03u19rg')
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (appConfig.GLOBAL_CONSTANTS.TESTMODE) {
          setIsUSLocation(true);
        } else if (data && data.location && data.location.country.code === 'US') {
          setIsUSLocation(true);
        }
      });

    return () => {
      document.body.classList.remove('body_ido');
    }
  }, [])

  useEffect(() => {
    setIsChecked(false);
  }, [currentStep])

  useEffect(() => {
    const success = searchParams.get("success")
    const fail = searchParams.get("fail")

    if (success !== null) {
      changeStep(3)
    }

    if (fail !== null) {
      changeStep(2)
    }
  }, [searchParams])

  const isAllowGoNext = useMemo(() => {
    if ((currentStep === 1 && isRecaptchaConfirmed) || (currentStep >=2 && currentStep < 6 && isChecked)) {
      return true;
    }

    return false;
  }, [currentStep, isRecaptchaConfirmed, isChecked])

  const changeStep = (newStep: number) => {
    setCurrentStep(newStep)
  };

  const onCancel = () => {
    navigate('/')
  }

  const copyAddress = () => {
    navigator.clipboard.writeText(address);

    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 2000);
  }

  const onContinue = () => {
    if (currentStep === 2 && isUSLocation) {
      changeStep(currentStep + 0.1);
    } else {
      changeStep(currentStep + 1);
    }
  }

  const updateKYCPhone = (e: any) => {
    const val = e.target.value;
    setKYCPhone(val);

    if (val.replace(/\D/g,'').length === 10) {
      setKYCPhoneValid(true);
    } else {
      setKYCPhoneValid(false);
    }
  }

  const startKYC = () => {
    setShowKYCPopup(true);
    // popupLogic.showPopup(true, 'vouched');
    // localStorageService.set('kyc-started', true);
    //
    // @ts-ignore
    const vouched = Vouched({
      // Optional verification properties.
      verification: {
        // verify the user's information
        // firstName: 'Gladys',
        // lastName: 'West',
        // used for the crosscheck feature
        // email: 'test@test.id',
        // phone: '000-111-2222'
        phone: kycPhone
      },

      appId: appConfig.GLOBAL_CONSTANTS.TESTMODE
        ? appConfig.GLOBAL_CONSTANTS.VOUCHED_PUBLIC_KEY_SANDBOX
        : appConfig.GLOBAL_CONSTANTS.RECAPTCHA_SITEKEY_LIVE,
      // your webhook for POST verification processing
      callbackURL: 'https://website.com/webhook',

      // mobile handoff
      crossDevice: true,
      crossDeviceQRCode: true,
      crossDeviceSMS: true,

      sandbox: appConfig.GLOBAL_CONSTANTS.TESTMODE,

      // called when the verification is completed.
      onDone: (job: any) => {
        // token used to query jobs
        console.log("Scanning complete", { token: job.token });

        // An alternative way to update your system based on the
        // results of the job. Your backend could perform the following:
        // 1. query jobs with the token
        // 2. store relevant job information such as the id and
        //    success property into the user's profile
        fetch(`/yourapi/idv?job_token=${job.token}`);

        // Redirect to the next page based on the job success
        if( job.result.success){
          window.location.replace(appConfig.GLOBAL_CONSTANTS.TESTMODE ? "http://localhost:3000/ido?success" : "https://hodlverse.com/ido?success");
        }else{
          window.location.replace(appConfig.GLOBAL_CONSTANTS.TESTMODE ? "http://localhost:3000/ido?fail" : "https://hodlverse.com/ido?fail");
        }
      },

      // theme
      theme: {
        name: 'avant',
      },
    });

    vouched.mount("#vouched-element");
  }

  return (
    <>
      <div className="body-content">
        <div className="top-wrapper w-full ido__top-wrapper">
          <Header/>
        </div>
        <div className="ido">
          <div className="container ido__container">
            {currentStep === 1 &&
              <section id="ido-step-1" className="ido__section">
              <h1 className="ido__title">Welcome to the official webpage for the HODLVERSE<br/>decentralized $MONEY token
                offering. Please ensure that the<br/>URL in your browser is <span
                  className="ido__link">https://www.hodlverse.com/ido</span> before<br/>proceeding further.</h1>
              <div className="ido__wrap ido__wrap_not-robot">
                <p className="ido__text">Please confirm that you are not a robot.</p>
              </div>
              <div className="ido__wrap ido__wrap_recaptcha">
                <ReCAPTCHA
                  onChange={(key: string | null) => {
                    if (key) {
                      setIsRecaptchaConfirmed(true)
                    }
                  }}
                  sitekey={appConfig.GLOBAL_CONSTANTS.TESTMODE ? appConfig.GLOBAL_CONSTANTS.RECAPTCHA_SITEKEY_SANDBOX : appConfig.GLOBAL_CONSTANTS.RECAPTCHA_SITEKEY_LIVE}
                />
              </div>
            </section>
            }
            {currentStep === 2 &&
              <section id="ido-step-2" className="ido__section">
              <h1 className="ido__title">Please confirm that the URL in your browser is<br/><span
                className="ido__link">https://www.hodlverse.com/ido</span></h1>
              <div className="ido__wrap ido__wrap_checkbox">
                <Checkbox label={<>I confirm that the URL in my browser is <span className="ido__link">https://www.hodlverse.com/ido</span></>}
                          checked={isChecked}
                          onChange={(checked) => setIsChecked(checked)} />
              </div>
            </section>
            }
            {currentStep === 2.1 &&
              <section id="ido-step-2.1" className="ido__section">
              <h1 className="ido__title ido__kyc-title">Your computer IP address is from the United States which
                requires a KYC check to proceed. Please check your VPN and correct your location if you feel this is an
                error.</h1>
              <p className="ido__kyc-subtitle">KYC data is not stored and services are provided by <a
                className="ido__kyc-subtitle_link" href="https://www.vouched.id/" target="_blank" rel="noreferrer">vouched.id</a></p>
              <div className="ido__kyc-phone-block-wrapper">
                <p className="ido__kyc-phone-title">Enter your phone number if you prefer to perform the KYC check on
                  your mobile.</p>
                <div className="ido__kyc-phone-wrapper">
                  <p className="ido__kyc-phone-code">+1</p>
                  {/*// @ts-ignore*/}
                  <InputMask mask="999-999-9999" maskChar="#" alwaysShowMask placeholder={"###-###-####"} value={kycPhone} onChange={updateKYCPhone} className="ido__kyc-phone-input" />
                </div>
              </div>
            </section>
            }
            {currentStep === 3 &&
              <section id="ido-step-3" className="ido__section">
              <h1 className="ido__title">Please confirm that you are depositing USDT (ERC20)<br/>Ethereum chain tokens
                for the offering.</h1>
              <div className="ido__wrap ido__wrap_checkbox">
                <Checkbox label={<>I confirm that I am depositing USDT (ERC20)<br/>Ethereum chain tokens for the offering.</>}
                          checked={isChecked}
                          onChange={(checked) => setIsChecked(checked)} />
              </div>
            </section>
            }
            {currentStep === 4 &&
              <section id="ido-step-4" className="ido__section">
              <h1 className="ido__title">Please confirm that you are not depositing your tokens from a<br/>wallet on a
                centralized exchange.</h1>
              <div className="ido__wrap ido__wrap_checkbox">
                <Checkbox label={<>I confirm that I am not depositing tokens from a wallet on a centralized exchange.</>}
                          checked={isChecked}
                          onChange={(checked) => setIsChecked(checked)} />
              </div>
            </section>
            }
            {currentStep === 5 &&
              <section id="ido-step-5" className="ido__section">
              <div className="ido__wrap ido__wrap_title-5">
                <h1 className="ido__title">Please confirm that you have read / accept the <a
                  className="ido__link ido__link_white" href="/" target="_blank" rel="noreferrer">token user agreement</a> and understand
                  that you will not receive your $MONEY tokens from the smart contract until December 17th to your
                  deposit wallet address.</h1>
              </div>
              <div className="ido__wrap ido__wrap_checkbox ido__wrap_checkbox-5">
                <Checkbox label={<>I confirm that I have read / accept the <a
                  className="ido__link ido__link_white" href="/" target="_blank" rel="noreferrer">token user agreement</a> and understand
                  that I will not receive my $MONEY tokens from the smart contract until December 17th to my deposit
                  wallet address.</>}
                          checked={isChecked}
                          onChange={(checked) => setIsChecked(checked)} />
              </div>
            </section>
            }
            {currentStep === 6 &&
              <section id="ido-step-6" className="ido__section">
              <div className="ido__wrap ido__wrap_multiple-title">
                <h1 className="ido__title">Please confirm that the URL in your browser is<br/><span
                  className="ido__link">https://www.hodlverse.com/ido</span></h1>
              </div>
              <div className="ido__wrap ido__wrap_multiple-title">
                <h1 className="ido__title">The MONEY IDO smart contract address is:<br/>
                  <span className={`ido__link ido__link_copy ${isAddressCopied && 'ido__link_copy-active'}`} onClick={copyAddress}>{address}{" "}
              <i className="icon-copy">
                <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.75 18V20.125C13.75 20.6886 13.5261 21.2291 13.1276 21.6276C12.7291 22.0261 12.1886 22.25 11.625 22.25H3.125C2.56141 22.25 2.02091 22.0261 1.6224 21.6276C1.22388 21.2291 1 20.6886 1 20.125V8.4375C1 7.87391 1.22388 7.33341 1.6224 6.9349C2.02091 6.53638 2.56141 6.3125 3.125 6.3125H5.25"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                    d="M5.25 3.125V15.875C5.25 16.4386 5.47388 16.9791 5.8724 17.3776C6.27091 17.7761 6.81141 18 7.375 18H15.875C16.4386 18 16.9791 17.7761 17.3776 17.3776C17.7761 16.9791 18 16.4386 18 15.875V6.56963C18 6.28653 17.9434 6.0063 17.8335 5.74538C17.7237 5.48447 17.5628 5.24813 17.3604 5.05025L13.8382 1.60562C13.4412 1.21745 12.908 1.00007 12.3528 1H7.375C6.81141 1 6.27091 1.22388 5.8724 1.6224C5.47388 2.02091 5.25 2.56141 5.25 3.125V3.125Z"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              <p className="ido__copied-text">Copied!</p>
            </span>
                </h1>
              </div>
              <div className="ido__wrap ido__wrap_multiple-title">
                <h1 className="ido__title">Please copy, paste, and deposit your USDT (ERC20) to the address above.</h1>
              </div>
              <div className="ido__wrap ido__wrap_multiple-title">
                <h1 className="ido__title">The exchange rate is 100 MONEY for 1 USDT. You will receive your MONEY tokens
                  on December 10th from the smart contract and trading will commence on Uniswap and Pancakeswap.</h1>
              </div>
            </section>
            }
            <div className="ido__manage-block">
              {currentStep > 1 && currentStep < 6 &&
                <button className="button button_orange-note ido__manage-btn ido__manage-btn_cancel"
                        onClick={onCancel}>
                  Cancel
                </button>
              }
              {currentStep !== 2.1 && currentStep < 6 &&
                <button className={`button button_active-orange ido__manage-btn ${!isAllowGoNext && 'button_disabled'}`}
                        disabled={!isAllowGoNext}
                        onClick={onContinue}>
                  Continue
                </button>
              }
              {currentStep === 2.1 &&
                <button className={`button button_active-orange ido__manage-btn ${!KYCPhoneValid && 'button_disabled'}`}
                        disabled={!KYCPhoneValid}
                        onClick={startKYC}>
                  Start KYC
                </button>
              }
            </div>
          </div>
          <div className="ido__screw">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path fill="#3c53d6" d="M0,0 L0,15 Q50,120 100,15 L 100,0"/>
            </svg>
          </div>
          <div className="ido__screw ido__screw_under">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path fill="white" d="M0,0 L0,15 Q50,120 100,15 L 100,0"/>
            </svg>
          </div>
        </div>
        <div className="ido__footer">
          <p className="ido__footer-text">View The HODLVERSE IDO Guide</p>
        </div>
      </div>
      <i className="ido__mayor"></i>
      <KYCPopup open={showKYCPopup} />
    </>
  )
}

export default IDOPage;