import Header from '../components/header'
import {useEffect} from "react";

const Page404: React.FC = () => {
  useEffect(() => {
    document.body.classList.add('body_ido');

    return () => {
      document.body.classList.remove('body_ido');
    }
  }, [])
  return (
    <>
      <div className="body-content">
        <div className="top-wrapper w-full page-404__top-wrapper">
          <Header />
        </div>
        <div className="page-404">
          <div className="container page-404__container">
            <h1 className="page-404__title">404</h1>
            <h2 className="page-404__subtitle">Page Not Found</h2>
            <h2 className="page-404__text">The page you are looking for does not exist!</h2>
          </div>
          <div className="page-404__manage-block">
            <a className="button button_active-orange page-404__manage-btn" href="/">RETURN TO HOME</a>
          </div>
          <div className="page-404__screw">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path fill="#3c53d6" d="M0,0 L0,15 Q50,120 100,15 L 100,0"/>
            </svg>
          </div>
          <div className="page-404__screw page-404__screw_under">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path fill="white" d="M0,0 L0,15 Q50,120 100,15 L 100,0"/>
            </svg>
          </div>
        </div>
      </div>
      <div className="page-404__footer-empty"></div>
      <i className="ido__mayor"></i>
    </>
  )
}

export default Page404;