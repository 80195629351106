import {useState} from "react";

const marketCapList = [
  { label: '25M', value: 25000000 },
  { label: '50M', value: 50000000 },
  { label: '75M', value: 75000000 },
  { label: '100M', value: 100000000 },
  { label: '250M', value: 250000000 },
  { label: '500M', value: 500000000 },
  { label: '750M', value: 750000000 },
  { label: '1B', value: 1000000000 },
  { label: '5B', value: 5000000000 },
  { label: '10B', value: 10000000000 },
  { label: '20B', value: 20000000000 },
  { label: '30B', value: 30000000000 },
]

const CalculatorBlock: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [futurePrice, setFuturePrice] = useState('00.05');
  const [futureMarketCap, setFutureMarketCap] = useState(marketCapList[0]);

  const selectItem = (item: any) => {
    setFutureMarketCap(item);

    const priceVal = item.value / 500000000;
    let priceValStr = priceVal.toString();
    if (!priceValStr.includes('.')) {
      priceValStr = `${priceValStr}.00`
    }
    const parts = priceValStr.split('.')
    while (parts[0].length < 2) {
      parts[0] = '0' + parts[0];
    }
    while (parts[1].length < 2) {
      parts[1] = parts[1] + '0';
    }
    priceValStr = `${parts[0]}.${parts[1]}`

    setFuturePrice(priceValStr);
  }

  return (
    <div className="calculator__digits-section">
      <div className="calculator__digit-row">
        <div className="calculator__input-block">
          <p className="calculator__input-title">Current Price of $MONEY</p>
          <div className="calculator__input-value-form">
            <p className="calculator__input-text"><span className="calculator__input-text_preffix">$</span>0.01
            </p>
          </div>
        </div>
        <div className="calculator__input-block">
          <p className="calculator__input-title">Future Price of $MONEY</p>
          <div className="calculator__input-value-form">
            <p className="calculator__input-text">
              <span className="calculator__input-text_preffix">$</span><span
              className="calculator__input-text_orange" id="offering-future-price">{futurePrice}</span></p>
          </div>
        </div>
      </div>
      <div className="calculator__digit-row">
        <div className="calculator__input-block">
          <p className="calculator__input-title">Current Marketcap</p>
          <div className="calculator__input-value-form">
            <p className="calculator__input-text"><span className="calculator__input-text_preffix">$</span>5,000,000
            </p>
          </div>
        </div>
        <div className="calculator__input-block">
          <p className="calculator__input-title">Future Marketcap</p>

          <div className={`calculator__input-value-form calculator__dropdown ${isOpen && 'calculator__dropdown_active'}`} onClick={() => setIsOpen(!isOpen)}>
            <p className="calculator__dropdown-val">${futureMarketCap.label}</p>
            <div className="calculator__dropdown-arrow-icon-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="27.64" height="18.62" viewBox="0 0 13.82 9.31"
                   className="calculator__dropdown-arrow-icon">
                <path fill="#fff"
                      d="M1514.9,8715.3a2.233,2.233,0,0,1-1.59-.66l-4.53-4.53a2.252,2.252,0,1,1,3.18-3.19l2.94,2.95,3.2-3.21a2.249,2.249,0,1,1,3.18,3.18l-4.79,4.8A2.25,2.25,0,0,1,1514.9,8715.3Z"
                      transform="translate(-1508.12 -8706)"/>
              </svg>
            </div>
            <div className="calculator__dropdown-menu">
              {marketCapList.map((option, index) => (
                <div key={index}
                     className="calculator__dropdown-menu-item"
                      onClick={() => selectItem(option)}>
                  ${option.label}
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default CalculatorBlock;