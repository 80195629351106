import {useErc20Contract} from "./useContract";
import {useWeb3React} from "@web3-react/core";
import {useCallback, useEffect, useMemo, useState} from "react";
import { BigNumber } from '@ethersproject/bignumber'
import {currencyToUnit} from "../functions/assets";


export enum ApprovalState {
    UNKNOWN = 'UNKNOWN',
    NOT_APPROVED = 'NOT_APPROVED',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
}

export function useApproveCallback(token: string, amountToApprove: string, spender: string
): [ApprovalState, () => Promise<void>] {
    const { account } = useWeb3React();

    const tokenContract = useErc20Contract(token)
    const [currentAllowance, setCurrentAllowance] = useState(BigNumber.from('0'))

    const getCurrentAllowance = useCallback( async ()=>{
        if (tokenContract) {
            const currentAllowance = await tokenContract?.allowance(account, spender)
            setCurrentAllowance(currentAllowance)
        }

    }, [spender, tokenContract, account])


    const approvalState: ApprovalState = useMemo(()=>{
        if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
        const rawBnTokenAmount = currencyToUnit(18, amountToApprove)
        const bnAmountToApprove =  BigNumber.from(`0x${rawBnTokenAmount.toString(16)}`)
        return currentAllowance.lt(bnAmountToApprove) ?
              ApprovalState.NOT_APPROVED
            : ApprovalState.APPROVED
    }, [currentAllowance, amountToApprove, spender])

    useEffect(()=>{
        getCurrentAllowance()
    }, [getCurrentAllowance])

    const approve = useCallback(async ()=>{
        if (approvalState !== ApprovalState.APPROVED) {
            if (tokenContract) {
                const rawBnTokenAmount = currencyToUnit(18, amountToApprove)
                const bnAmountToApprove =  BigNumber.from(`0x${rawBnTokenAmount.toString(16)}`)
                const tx = await tokenContract?.approve(spender, bnAmountToApprove);
                console.log(tx)
            }

        }
    }, [spender, amountToApprove, tokenContract])


    return  useMemo(()=>{
        return [approvalState, approve]
    }, [approvalState, approve])

}

export function useTokenBalance(token: string) {
    const { account } = useWeb3React();
    const tokenContract = useErc20Contract(token)

    const [tokenBalance, setTokenBalance] = useState(BigNumber.from('0'))

    const getBalance = useCallback( async ()=>{
        if (tokenContract) {
            const currentBalance = await tokenContract?.balanceOf(account)
            setTokenBalance(currentBalance)
        }

    }, [tokenContract, account])

    useEffect(()=>{
        getBalance()
    }, [getBalance])

    return useMemo(()=>{
        return tokenBalance
    }, [tokenBalance])
}
