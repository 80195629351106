import Slider from "react-slick";

import {useRef} from "react";

const elList = [
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Transparency',
    description: 'Transparency is crucial for a city towards modern and reliable governance. Fiscal transparency in a government (in this case, network) will not only improve public confidence, but also raise government efficiency and accountability, and eliminate corruptive vulnerabilities. HODLVERSE will achieve fiscal transparency by publishing quarterly financial reports and updates.'
  },
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Equity',
    description: 'Rapid scaling and growth are important for every new business and network, including HODLVERSE. To achieve this, HODLVERSE will implement a systematic and sustainable user acquisition and retention strategy by consulting from reputable growth firms (growth advisory, market makers, etc.). This will enable the business to continue generating revenue and have the resources to continue to improve the platforms, in turn attracting more customers through a flywheel effect.'
  },
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Security',
    description: 'Security is paramount for any growing business and city network to build trust among new and existing users. To mitigate security risks and vulnerabilities, HODLVERSE employs 3rd party security advisory and audit firms to regularly perform security assessments, and process and technology audits.'
  },
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Efficiency',
    description: 'An important success factor of HODLVERSE lies in the ability to efficiently and effectively bring to market secure product and service offerings. In the blockchain, financial and technology industries, network performance, efficiency, and optimization are important to create the best user experience for the network. This is achieved by HODLVERSE through its overall network vision, senior leadership, engineering talent, advisors, and the agile and security methodologies that we adopt when developing products and services.'
  },
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Engagement',
    description: 'As with all growing businesses and networks, HODLVERSE places great emphasis on customer acquisition and engagement. While cryptocurrencies and NFTs are popular novel concepts, we intend to push boundaries by creating new user experiences, interactions, and levels of engagement that do not exist in the blockchain industry today. We aim to redefine finance through new experiences that provide freedom and convenience.'
  },
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Compliance',
    description: 'We recognize that with network growth comes more complexity and more responsibility. HODLVERSE has a compliance-first mindset to make its growing network safe and sustainable for all. We have employed 3rd party compliance and legal firms to advise us on controls and regulatory technology implementation to protect its users and the entire network. We will always ensure that the HODLVERSE business and network are safe, secure, and compliant with jurisdictions all around the world.'
  },
  {
    icon: 'icon-bank',
    preTitle: 'HODLVERSE Urban Governance',
    title: 'Consensus',
    description: 'As a decentralized organization, HODLVERSE follows the spirit of community participation and inclusivity. The network will never tolerate decisions to be made by centralized parties or groups. Citizens vote every quarter on upgrades, features, or important decisions related to the direction of its products, services, and protocols.'
  },
]

const HeaderScrollBlock: React.FC = () => {
  const slider = useRef<any>();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    centerPadding: "0",
    focusOnSelect: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    // lazyLoad: "progressive" as LazyLoadTypes,
    swipeToSlide: true,
    arrows: false,
    afterChange: (currentSlide: number) => {
      // const pageCount = Math.floor(nftList.length / pageSize);
      //
      // let correctDotIndex = Math.ceil(currentSlide / pageSize);
      // if (correctDotIndex > pageCount) {
      //   correctDotIndex = pageCount;
      // }
      //
      // setActiveDotIndex(correctDotIndex);
    },
  };

  return (
    <div className="economy-head">
      <Slider {...settings} ref={slider} className="economy-head__slide-list">
        {elList.map((item, index) => (
          <div key={index}>
            <div className="economy-head__slide">
              <div className="economy-head__info-box">
                <p className="economy-head__preff-title">{item.preTitle}</p>
                <p className="economy-head__title">{item.title}</p>
                <div className="economy-head__icon-wrapper invisible_not-mobile_tablet">
                  <i className={`economy-head__icon ${item.icon}`}></i>
                </div>
                <p className="economy-head__description">
                  {item.description}
                </p>
              </div>
              <div className="economy-head__icon-wrapper invisible_mobile_tablet">
                <i className={`economy-head__icon ${item.icon}`}></i>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="container">
        <div className="nft__pagination-block economy-head__pagination-block">
          <div className="nft__page-btn nft__page-btn_prev" onClick={() => slider?.current?.slickPrev()}></div>
          {/*<div class="nft__page-line">*/}
          {/*  <div class="nft__pages-wrapper">*/}
          {/*    <div class="nft__page-circle nft__page-circle_active" id="economy-header__page-circle-id-1"></div>*/}
          {/*    <div class="nft__page-circle" id="economy-header__page-circle-id-2"></div>*/}
          {/*    <div class="nft__page-circle" id="economy-header__page-circle-id-3"></div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="nft__page-btn nft__page-btn_next" onClick={() => slider?.current?.slickNext()}></div>
        </div>
      </div>
    </div>
  )
}

export default HeaderScrollBlock;