import {useEffect, useState} from "react";
import { DateTime } from "luxon";
import Modal from "./modal"
import SubscriptionForm from "./subscriptionForm";

interface IdoPopupProps {
  open: boolean;
  onClose: () => void;
}

const IdoPopup: React.FC<IdoPopupProps> = ({open, onClose}) => {
  const [isBeforeDate, setIsBeforeDate] = useState(true);

  useEffect(() => {
    if (open) {
      const idoDateEST = DateTime.fromISO("2022-03-04T09:00:00", { zone: "America/New_York" });
      const currentUserTimeEST = DateTime.fromISO(new Date().toISOString(), { zone: "America/New_York" });

      if (currentUserTimeEST < idoDateEST) {
        setIsBeforeDate(true);
      } else {
        setIsBeforeDate(false);
      }
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        {isBeforeDate ? (
          <>
            <p className="popup__title popup__title_before">Whoa There! We’re Just As Excited As You.</p>
            <p className="popup__description popup__description_before">
              $MONEY will be listed and available on the exchanges below on March 4th. Buy $MONEY in advance during
              the
              <a href="https://hodlverse.gitbook.io/docs/the-hodlverse-economy/token-offering-and-ido" target="_blank" rel="noreferrer"
                 className="link">IDO on February 25th</a>. Please sign up for reminders on these monumental dates.
            </p>
          </>
        ) : (
          <>
            <p className="popup__title popup__title_after" id="popup-form-title-after-id">Thank You for Your Interest
              in Becoming a Citizen!</p>
            <p className="popup__description popup__description_after">$MONEY is available on the exchanges below.
              Sign up on our mailing list for the latest updates about the HODLVERSE metaverse.</p>
          </>
        )}

        <div className="popup__list-wrapper">
          <div className="popup__list-item">
            <i className="popup__list-icon icon-competition-uniswap"></i>
          </div>
          {/*<div class="popup__list-item">*/}
          {/*  <i class="popup__list-icon icon-pancake-dark"></i>*/}
          {/*</div>*/}
          <div className="popup__list-item">
            {isBeforeDate ? <i className="popup__list-icon icon-centralized-exchange"></i> : <i className="popup__list-icon icon-mexc"></i>}
          </div>
        </div>
      </div>
      <SubscriptionForm onClose={onClose} />
    </Modal>
  )
};

export default IdoPopup;