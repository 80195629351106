import Header from '../components/header'

const Page503: React.FC = () => {
  return (
    <>
      <div className="body-content">
        <div className="top-wrapper w-full page-503__top-wrapper">
          <Header/>
        </div>
        <div className="page-503">
          <div className="container page-503__container">
            <i className="icon-network page-503__icon"></i>
            <h2 className="page-503__text">We’re having difficulty connecting you to the server. Please check your
              connection and try again later.</h2>
          </div>
          <div className="page-503__manage-block">
            <a className="button button_active-orange page-503__manage-btn" href="/">RETRY</a>
          </div>
          <div className="page-503__screw">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path fill="#3c53d6" d="M0,0 L0,15 Q50,120 100,15 L 100,0"/>
            </svg>
          </div>
          <div className="page-503__screw page-503__screw_under">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
              <path fill="#EEEEEE" d="M0,0 L0,15 Q50,120 100,15 L 100,0"/>
            </svg>
          </div>
        </div>
      </div>
      <div className="page-503__footer-empty"></div>
    </>
  )
}

export default Page503;