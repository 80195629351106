import {useLocation, useNavigate} from "react-router-dom";
import IdoPopup from "./idoPopup";
import {useState} from "react";

const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showIdoPopup, setShowIdoPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const path = location.pathname;

  const navigateTo = (link: string) => {
    navigate(link)
  }

  return (
    <>
      <header className="header">
        <div className="container header__container">
          <div className="header__logo-wrapper">
            <i className="header__logo icon"></i>
          </div>
          <div className="header__content-wrapper">
            <div className="header__menu-wrapper">
              <nav className="nav-menu">
                <div className="nav-menu__container">
                  <div className={`nav-menu__item ${path === '/' && 'nav-menu__item_active'}`} onClick={() => navigateTo('/')}>Home</div>
                  <div className={`nav-menu__item ${path === '/economy' && 'nav-menu__item_active'}`} onClick={() => navigateTo('/economy')}>Economy</div>
                  <div className={`nav-menu__item ${path === '/offering' && 'nav-menu__item_active'}`} onClick={() => navigateTo('/offering')}>Offering</div>
                  <a className={`nav-menu__item`} href="https://hodlverse.gitbook.io/docs" target="_blank" rel="noreferrer">Docs</a>
                </div>
              </nav>
            </div>
            <div className="header__manage-wrapper">
              <div className="header__manage-btn header__manage-btn_start">
                <a className="button button_orange-note" href="https://app.hodlvalley.com/" target="_blank" rel="noreferrer">Start
                  dapp</a>
              </div>
              <div className="header__manage-btn header__manage-btn_money">
                <button className="button button_orange" onClick={() => setShowIdoPopup(true)}>buy money</button>
              </div>
              <div className={`header__hamburger-wrapper header__dropdown ${showDropdown && 'header__dropdown_active'}`} onClick={() => setShowDropdown(show => !show)}>
                <div className="header__hamburger">
                  <hr className="header__hamburger-line"/>
                  <hr className="header__hamburger-line"/>
                  <hr className="header__hamburger-line"/>
                </div>
                <div className="header__dropdown-menu">
                  <div className={`header__dropdown-menu-item ${path === '/' && 'header__dropdown-menu-item_active'}`} onClick={() => navigateTo('/')}>Home</div>
                  <div className={`header__dropdown-menu-item ${path === '/economy' && 'header__dropdown-menu-item_active'}`} onClick={() => navigateTo('/economy')}>Economy</div>
                  <div className={`header__dropdown-menu-item ${path === '/offering' && 'header__dropdown-menu-item_active'}`} onClick={() => navigateTo('/offering')}>Offering</div>
                  <a className={`header__dropdown-menu-item`} href="https://hodlverse.gitbook.io/docs" target="_blank" rel="noreferrer">Docs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <IdoPopup open={showIdoPopup} onClose={() => setShowIdoPopup(false)} />
    </>
  )
};

export default Header;