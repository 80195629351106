import Header from '../../components/header'
import Footer from '../../components/footer'
import SocialFooter from '../../components/socialFooter'
import YoutubeVideoPopup from '../../components/youtubeVideoPopup'

import {Link} from "react-router-dom";

import {ReactComponent as WhiteBox} from "../../assets/icons/home-header-whitebox.svg";
import {ReactComponent as WhiteBoxMobile} from "../../assets/icons/home-header-whitebox-mobile.svg";

import {useState} from "react";
import PropertiesBlock from "./PropertiesBlock";
import FarmBlock from "./FarmBlock";
import PartnerShipBlock from "./PartnershipBlock";
import NFTBlock from "./NFTBlock";

const HomePage: React.FC = () => {
  const [showYoutubePopup, setShowYoutubePopup] = useState(false);

  return (
    <>
      <div className="top-wrapper top-wrapper_home top-wrapper_placeholder w-full">
        <Header/>
        <div className="head">
          <div className="head__wrapper">
            <WhiteBox className="head__main-svg"/>
            <div className="head__form-wrapper invisible_mobile">
              <WhiteBoxMobile/>
              <div className="head__form-wrapper-content">
                <h1 className="head__title">The most simple and fun way to manage your cryptocurrency.</h1>
                <h5 className="head__subtitle">The HODLVERSE is a virtual tokenized world and decentralized network that
                  helps citizens manage their digital assets.</h5>
                <div className="head__manage-block">
                  <div className="head__button-wrapper">
                    <Link className="button head__try-btn" to={'/hodl'} target="_blank">Preview
                      <div className="head__play-ring">
                        <div className="head__small-play-icon-wrapper">
                          <i className="icon head__small-play-icon"></i>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="head__button-wrapper head__button-wrapper_play" id="play-video-btn-id" onClick={() => setShowYoutubePopup(true)}>
                    <p className="head__play-button-text">Play video</p>
                    <div className="head__play-button-icon"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container invisible_not-mobile">
            <div className="head__form-wrapper">
              <h1 className="head__title">The most simple & fun way to manage your cryptocurrency.</h1>
              <h5 className="head__subtitle">The HODLVERSE is a virtual tokenized world and decentralized network that
                helps citizens manage their digital assets.</h5>
              <div className="head__manage-block">
                <div className="head__button-wrapper">
                  <Link className="button head__try-btn" to={'/hodl'} target="_blank">Preview
                    <div className="head__play-ring">
                      <div className="head__small-play-icon-wrapper">
                        <i className="icon head__small-play-icon"></i>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="head__button-wrapper head__button-wrapper_play" id="play-video-btn-id" onClick={() => setShowYoutubePopup(true)}>
                  <p className="head__play-button-text">Play video</p>
                  <div className="head__play-button-icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="statistic">
        <div className="container">
          <p className="title statistic__title">A network focused on blockchain and metaverse interoperability.</p>
          <p className="subtitle statistic__subtitle">The $MONEY network focuses on bridging protocols and providing
            value to the contributors of the network.</p>
          <div className="statistic__data-list">
            <div className="statistic__option-row">
              <div className="statistic__option">
                <p className="statistic__option-title">Price of MONEY</p>
                <p className="statistic__option-value">TBD</p>
              </div>
              <div className="statistic__option">
                <p className="statistic__option-title">Total Liquidity</p>
                <p className="statistic__option-value">TBD</p>
              </div>
              <div className="statistic__option">
                <p className="statistic__option-title">Total Pairs</p>
                <p className="statistic__option-value">TBD</p>
              </div>
            </div>
            <div className="statistic__option-row">
              <div className="statistic__option statistic__option_large">
                <p className="statistic__option-title">Total Volume</p>
                <p className="statistic__option-value">TBD</p>
              </div>
              <div className="statistic__option statistic__option_large">
                <p className="statistic__option-title">Reward Distributed</p>
                <p className="statistic__option-value">TBD</p>
              </div>
            </div>
          </div>
        </div>
        <div className="statistic__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#2a0e3e"
               fill-rule="inherit">
            <path d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>
      <div className="best-blockchain">
        <div className="container">
          <p className="title best-blockchain__title">A Digital World that Represents<br/>the Best Blockchains</p>
          <p className="subtitle best-blockchain__subtitle">The HODLVERSE has dedicated cities and unique NFT properties
            for different blockchain networks.</p>
        </div>
        <div className="best-blockchain__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="white"
               fill-rule="inherit">
            <path d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <PropertiesBlock />

      <div className="benefits">
        <div className="container">
          <div className="benefits__head">
            <p className="title title_light benefits__title">HODLVERSE<br className={'invisible_tablet'}/>at a Glance</p>
            <p className="subtitle subtitle_light benefits__subtitle">The HODLVERSE is a virtual tokenized world and
              decentralized network that helps citizens manage their digital assets.</p>
          </div>
          <div className="benefits__list">
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__crosschain"></i>
                </div>
                <p className="benefit__title">Cross-Chain</p>
                <p className="benefit__subtitle">Securely swap between Bitcoin and tokens on Ethereum and Binance Smart
                  Chain</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__noncustodial"></i>
                </div>
                <p className="benefit__title">Non-Custodial</p>
                <p className="benefit__subtitle">Your keys. Your coins. Connect your favorite wallet to use on
                  HODLVERSE</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__gamification"></i>
                </div>
                <p className="benefit__title">Gamification</p>
                <p className="benefit__subtitle">Enjoy multiplayer and trading battles by inviting other users to your
                  customized
                  city</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__swap"></i>
                </div>
                <p className="benefit__title">Decentralized Swaps</p>
                <p className="benefit__subtitle">Token swaps execute directly between wallets and smart contracts with
                  no humans
                  involved</p>
              </div>
            </div>

            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__nft"></i>
                </div>
                <p className="benefit__title">Functional NFTs</p>
                <p className="benefit__subtitle">Owners of NFT franchise properties earn revenue from the attached DApp
                  or
                  protocol</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__anonymous"></i>
                </div>
                <p className="benefit__title">Anonymous</p>
                <p className="benefit__subtitle">The HODLVERSE metaverse is a private network with no KYC, no
                  registration and
                  full anonymity</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__farming"></i>
                </div>
                <p className="benefit__title">Yield Farming</p>
                <p className="benefit__subtitle">Stake both $MONEY and $HVLP tokens to maximize your rewards
                  potential</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__sharing"></i>
                </div>
                <p className="benefit__title">Revenue Sharing</p>
                <p className="benefit__subtitle">Rewards from token buybacks are distributed as rewards to liquidity
                  providers and
                  stakers</p>
              </div>
            </div>

            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__buyback"></i>
                </div>
                <p className="benefit__title">Token Buybacks</p>
                <p className="benefit__subtitle">Revenue from fees are used to buyback $MONEY and replenish network
                  reserves</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__governance"></i>
                </div>
                <p className="benefit__title">Governance</p>
                <p className="benefit__subtitle">Citizens of HODLVERSE earn the right to vote on economic and technical
                  issues
                  for the network</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__achievements"></i>
                </div>
                <p className="benefit__title">Achievements</p>
                <p className="benefit__subtitle">Earn achievements and watch your city evolve alongside your financial
                  journey</p>
              </div>
            </div>
            <div className="benefits__item-wrapper">
              <div className="benefit">
                <div className="benefit__icon-wrapper">
                  <i className="benefit__icon icon__blockchain-bridges"></i>
                </div>
                <p className="benefit__title">Blockchain Bridges</p>
                <p className="benefit__subtitle">HODLVERSE bridges seamlessly enable $MONEY to be used on other
                  blockchains</p>
              </div>
            </div>
          </div>
        </div>
        <div className="benefits__screw benefits__screw_bottom">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
               fill-rule="inherit">
            <path fill="#2c41bb" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>
      <div className="swap">
        <div className="container swap__container">
          <div className="swap__icon-wrapper">
            <i className="swap__icon"></i>
          </div>
          <div className="swap__content-wrapper">
            <p className="title swap__title">Cross-Chain Decentralized Swaps</p>
            <p className="subtitle swap__subtitle">Trustless decentralized exchanges facilitated by 3rd party audited
              technology.</p>
          </div>
        </div>
        <div className="swap__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
               fill-rule="inherit">
            <path fill="white" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <NFTBlock />

      <FarmBlock />
      <div className="reward">
        <div className="reward__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
               fill-rule="inherit">
            <path fill="white" d="M0,100 C50,0 50,0 100,100"/>
          </svg>
        </div>
        <div className="container">
          <div className="reward__row">
            <div className="reward__info-column">
              <p className="title reward__title">Play-to-Earn Rewards.</p>
              <p className="subtitle reward__subtitle">Crypto Made Fun and Easy.</p>
              <p className="reward__description">Trading battles, leaderboards, in-game purchases to customize your
                city, and more!</p>
            </div>
            <div className="reward__icon-wrapper">
              <i className="reward__icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="best">
        <div className="best__screw best__screw_top">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
               fill-rule="inherit">
            <path fill="#f5f7fa" d="M0,100 C50,0 50,0 100,100"/>
          </svg>
        </div>
        <div className="container">
          <p className="title best__title">The Best Blockchains<br/>And Networks In The World</p>
          <p className="subtitle best__subtitle">The HODLVERSE supports the most popular blockchain<br/>and token
            protocols used around the world.</p>
          <div className="best__token-list">
            <div className="best__token-wrapper">
              <a className="best__token" href="https://ethereum.org/en/foundation/" target="_blank" rel="noreferrer">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper">
                  <i className="best__token-icon icon-eth"></i>
                </div>
              </a>
            </div>
            <a className="best__token-wrapper" href="https://bitcoin.org/en/" target="_blank" rel="noreferrer">
              <div className="best__token">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper">
                  <i className="best__token-icon icon-btc"></i>
                </div>
              </div>
            </a>
            <a className="best__token-wrapper" href="https://polygon.technology/" target="_blank" rel="noreferrer">
              <div className="best__token">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper">
                  <i className="best__token-icon icon-some-token"></i>
                </div>
              </div>
            </a>
            <a className="best__token-wrapper" href="https://www.binance.org/en/smartChain" target="_blank" rel="noreferrer">
              <div className="best__token">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper">
                  <i className="best__token-icon icon-binance-coin"></i>
                </div>
              </div>
            </a>
          </div>
          <div className="best__token-list best__token-list_second">
            <a className="best__token-wrapper" href="https://www.avax.network/" target="_blank" rel="noreferrer">
              <div className="best__token">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper">
                  <i className="best__token-icon icon-avalanche"></i>
                </div>
              </div>
            </a>
            <a className="best__token-wrapper" href="https://fantom.foundation/" target="_blank" rel="noreferrer">
              <div className="best__token">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper">
                  <i className="best__token-icon icon-fantom"></i>
                </div>
              </div>
            </a>
            <div className="best__token-wrapper">
              <div className="best__token">
                <div className="best__token-bg"></div>
                <div className="best__token-icon-wrapper best__token-icon-wrapper_question">
                  <i className="best__token-icon icon-question"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="best__screw best__screw_bottom">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
               fill-rule="inherit">
            <path fill="#f5f7fa" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>
      <div className="competition">
        <div className="competition__shadow"></div>
        <div className="container">
          <p className="title title_light competition__title">Levels Above the Competition</p>
          <p className="subtitle subtitle_light competition__subtitle">HODLVERSE is disrupting the competitive landscape
            in the blockchain industry.</p>
          <div className="competition__table-wrapper">
            <div className="competition__header-row">
              <div className="competition__column competition__column_1"></div>
              <div className="competition__column competition__column_2">
                <div className="competition__competitor-icon-wrapper">
                  <i className="competition__competitor-icon icon-competition-hodl"></i>
                </div>
              </div>
              <div className="competition__column competition__column_3">
                <div className="competition__competitor-icon-wrapper">
                  <i className="competition__competitor-icon icon-competition-uniswap"></i>
                </div>
              </div>
              <div className="competition__column competition__column_4">
                <div className="competition__competitor-icon-wrapper">
                  <i className="competition__competitor-icon icon-competition-pancake"></i>
                </div>
              </div>
              <div className="competition__column competition__column_5">
                <div className="competition__competitor-icon-wrapper">
                  <i className="competition__competitor-icon icon-competition-binance"></i>
                </div>
              </div>
            </div>

            <div className="competition__form-wrapper">
              <div className="competition__splitter-wrapper">
                <div className="competition__splitter-block competition__splitter-block_defi">DeFi</div>
                <div className="competition__splitter-block">NFTs</div>
                <div className="competition__splitter-block competition__splitter-block_gamefi">GameFi</div>
                <div className="competition__splitter-block competition__splitter-block_network">Network</div>
              </div>

              <div className="competition__main-table-wrapper">
                <div className="competition__main-table">
                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Decentralized Exchanges - Cross-Chain Swaps</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Decentralized Exchanges - AMM Liquidity Pools</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_4">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Yield Farming, Staking, Governance</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_4">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Native Wallet</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3">
                    </div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">NFT Marketplace</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">NFT Turnkey Franchise Properties/Businesses</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Gamification (Play-to-Earn, Battles, Achievements, and
                        more)</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Metaverse customizations</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Revenue Sharing</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_5"></div>
                  </div>

                  <div className="competition__row">
                    <div className="competition__column competition__column_1">
                      <p className="competition__main-table-text">Token Buybacks</p>
                    </div>
                    <div className="competition__column competition__column_2">
                      <div className="success"><i className="icon icon-success"></i></div>
                    </div>
                    <div className="competition__column competition__column_3"></div>
                    <div className="competition__column competition__column_4"></div>
                    <div className="competition__column competition__column_5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="competition__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
               fill-rule="inherit">
            <path fill="#3d5ed9" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <PartnerShipBlock />

      <YoutubeVideoPopup open={showYoutubePopup} onClose={() => setShowYoutubePopup(false)} />
      <Footer/>
      <SocialFooter/>
    </>
  )
}

export default HomePage;