import React, {useCallback} from 'react';
import './App.css';
import "./styles/index.scss";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Wrapper from "./Layouts/Wrapper";
import { Web3Provider } from "@ethersproject/providers";
import Home from "./pages/Home/Home"
import Bridge from "./pages/Bridge"
import Economy from "./pages/Economy/Economy"
import Offering from "./pages/Offering/Offering"
import IDOPage from "./pages/IDO/IDO"
import Page404 from "./pages/404"
import Page503 from "./pages/503"
import HodlversePage from "./pages/Hodlverse/Hodlverse"

function App() {

  const getLibrary = useCallback((provider)=>{
    const library = new Web3Provider(
        provider,
        typeof provider.chainId === 'number'
            ? provider.chainId
            : typeof provider.chainId === 'string'
            ? parseInt(provider.chainId)
            : 'any'
    )
    library.pollingInterval = 15_000

    return library
  }, [])
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/economy" element={<Economy />} />
            <Route path="/offering" element={<Offering />} />
            <Route path="/ido" element={<IDOPage />} />
            <Route path="/bridge" element={<Bridge />} />
            <Route path="/hodl" element={<HodlversePage />} />
            <Route path="/503" element={<Page503 />} />
            <Route
              path="*"
              element={<Page404 />}
            />
            {/*<Route path="/" element={<App />}>*/}
            {/*  <Route index element={<Home />} />*/}
            {/*  <Route path="teams" element={<Teams />}>*/}
            {/*    <Route path=":teamId" element={<Team />} />*/}
            {/*    <Route path="new" element={<NewTeamForm />} />*/}
            {/*    <Route index element={<LeagueStandings />} />*/}
            {/*  </Route>*/}
            {/*</Route>*/}
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
