import Header from '../../components/header'
import Footer from '../../components/footer'
import SocialFooter from '../../components/socialFooter'
import HeaderScrollBlock from "./HeaderScrollBlock";
import CalculatorBlock from "./CalculatorBlock";

import { ReactComponent as RoadmapIcon } from "../../assets/icons/offeringRoad.svg";
import { ReactComponent as RoadmapMobileIcon } from "../../assets/icons/offeringRoadMobile.svg";

const OfferingPage: React.FC = () => {
  return (
    <>
      <div className="top-wrapper w-full">
        <Header/>

        <HeaderScrollBlock />

        <div className="offering-head__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#3c53d6" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <div className="tokenomics">
        <div className="container">
          <p className="title tokenomics__title">Clean Tokenomics With Plenty of Upside</p>
          <p className="subtitle tokenomics__subtitle">A clean cap table with a low circulating supply that is community
            owned.<br className="invisible_mobile"/>No allocations for VC’s. No pre-sales. No advisors. No whales. No
              dumping.</p>
          <div className="tokenomics__icon-wrapper">
            <i className="tokenomics__icon icon-tokenomics"></i>
          </div>
          <div className="tokenomics__legend">
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_yellow"></div>
              <div className="tokenomics__legend-text">30% Token IDO / Ecosystem Development</div>
            </div>
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_blue"></div>
              <div className="tokenomics__legend-text">25% Marketing</div>
            </div>
            {/*<div class="tokenomics__legend-item">*/}
            {/*  <div class="tokenomics__legend-circle tokenomics__legend-circle_purple"></div>*/}
            {/*  <div class="tokenomics__legend-text">25% Ecosystem Development</div>*/}
            {/*</div>*/}
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_red"></div>
              <div className="tokenomics__legend-text">20% HODL Reserve</div>
            </div>
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_emerald"></div>
              <div className="tokenomics__legend-text">5% Liquidity Pool Pairing</div>
            </div>
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_green"></div>
              <div className="tokenomics__legend-text">20% Team</div>
            </div>
          </div>
        </div>
      </div>
      <div className="growth">
        <div className="growth__screw growth__screw_top">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#f5f7fa" d="M0,100 C50,0 50,0 100,100"/>
          </svg>
        </div>

        <div className="container">
          <p className="title growth__title">Designed for Decades of Growth and Operations</p>
          <p className="subtitle growth__subtitle">Raised funds are leveraged exactly where you expect:<br/>Engineering,
            marketing,
            and network liquidity.</p>
          <div className="growth__icon-wrapper">
            <i className="growth__icon icon-growth"></i>
          </div>
          <div className="tokenomics__legend growth__legend">
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_yellow"></div>
              <div className="tokenomics__legend-text">10% Operations, Administrative and Legal</div>
            </div>
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_blue"></div>
              <div className="tokenomics__legend-text">50% Engineering</div>
            </div>
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_green"></div>
              <div className="tokenomics__legend-text">20% Initial AMM Liquidity Pools in the HODLVERSE</div>
            </div>
            <div className="tokenomics__legend-item">
              <div className="tokenomics__legend-circle tokenomics__legend-circle_red"></div>
              <div className="tokenomics__legend-text">20% Marketing</div>
            </div>
          </div>
        </div>

        <div className="growth__screw growth__screw_bottom">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#f5f7fa" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>
      <div className="decentralize">
        <div className="container">
          <p className="title title_light decentralize__title">A Clear and Transparent<br/>Decentralized Token Offering
          </p>
          <p className="subtitle subtitle_light decentralize__subtitle">An offering powered only by decentralized smart
            contracts.<br className="invisible_mobile"/>No
              pre-sales or humans allowed.</p>
          <div className="decentralize__list">
            <div className="decentralize__list-circle-row"></div>
            <div className="decentralize__list-info-row">
              <div className="decentralize__info-item">
                <p className="decentralize__info-item-number">1</p>
                <p className="decentralize__info-item-title">Contract<br/>Opens</p>
                <p className="decentralize__info-item-description">HODLVERSE IDO token swap smart contract opens on a
                  specified
                  date</p>
              </div>
              <div className="decentralize__info-item">
                <p className="decentralize__info-item-number">2</p>
                <p className="decentralize__info-item-title">Deposit<br/>Tokens</p>
                <p className="decentralize__info-item-description">Participants deposit USDT Tokens to the HODLVERSE IDO
                  Token
                  swap smart contract</p>
              </div>
            </div>
            <div className="decentralize__list-info-row decentralize__list-info-row_second">
              <div className="decentralize__info-item">
                <p className="decentralize__info-item-number">3</p>
                <p className="decentralize__info-item-title">Token<br/>Distribution</p>
                <p className="decentralize__info-item-description">IDO Token swap smart contract closes on a specified
                  and will
                  send the
                  corresponding $MONEY Tokens to the wallet addresses that deposited USDT Tokens</p>
              </div>
              <div className="decentralize__info-item">
                <p className="decentralize__info-item-number">4</p>
                <p className="decentralize__info-item-title">Listing<br/>Day</p>
                <p className="decentralize__info-item-description">$MONEY Token will be listed on Uniswap and
                  HODLVERSE</p>
              </div>
            </div>
          </div>
        </div>
        <div className="decentralize__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#3e66dc" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>
      <div className="roadmap">
        <p className="title roadmap__title">Roadmap</p>
        <p className="subtitle roadmap__subtitle">The HODLVERSE ecosystem and network is designed to create solutions in
          the
          blockchain industry for current and future generations to come.</p>
        <div className="decentralize__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="white" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <div className="road">
        <div className="road__top-road-wrapper">
          <div className="road__top-curved-line">
            <RoadmapMobileIcon className="invisible_mobile_tablet" />

            <RoadmapIcon className="invisible_not-mobile_tablet" />
          </div>

          <section>
            <div className="road__item road__item_active">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">Concept development</p>
            </div>
            <div className="road__item road__item_active">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">Business planning & execution</p>
              <div className="road__item-benefit-list">
                <p className="road__item-benefit">Legal</p>
                <p className="road__item-benefit">Engineering</p>
                <p className="road__item-benefit">Operations</p>
                <p className="road__item-benefit">Marketing</p>
              </div>
            </div>
            <div className="road__item road__item_active">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">Business approval & incorporation</p>
            </div>
            <div className="road__item road__item_active">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">Platform and smart contract software development lifecycle</p>
            </div>
            <div className="road__item road__item_active">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">3rd-Party and community smart contract security audits</p>
            </div>


            <div className="road__item invisible_mobile_tablet"></div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Listing and liquidity pool creation on Uniswap</p>
            </div>
            <div
              className="road__item road__item_active road__item_current road__item_active-no-line road__item_active-current-mobile">
              <div className="road__item-circle"><i
                className="road__item-circle-checkbox road__item-circle-checkbox_current"></i></div>
              <p className="road__item-title">HODLVERSE launchpad and token offerings</p>
            </div>
            <div className="road__item road__item_active road__item_active-no-line">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">Testnet release of the HODLVERSE on the Ethereum blockchain</p>
            </div>
            <div className="road__item road__item_active road__item_active-no-line">
              <div className="road__item-circle"><i className="road__item-circle-checkbox"></i></div>
              <p className="road__item-title">MONEY Token mint on the Ethereum blockchain</p>
            </div>


            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">3rd-Party smart contract and platform security audits</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Mainnet release of the HODLVERSE on the Ethereum blockchain</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Listing and liquidity pool creation on HODLVERSE</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Mainnet release of the HODLVERSE on the ETH EVM sidechains</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">MONEY liquidity pool creation for all sidechains</p>
            </div>


            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Platform localization </p>
              <div className="road__item-benefit-list">
                <p className="road__item-benefit">Language support for various regions</p>
              </div>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Network API’s & developer kits</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">Gameplay, gamification, & rewards integration</p>
              <div className="road__item-benefit-list">
                <p className="road__item-benefit">Transaction history and incentives</p>
                <p className="road__item-benefit">Leaderboards</p>
                <p className="road__item-benefit">In-game character battles</p>
                <p className="road__item-benefit">In-game purchases</p>
              </div>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">More cross-chain 3rd Party token integration</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">HODLVERSE NFT franchise property deployment</p>
            </div>
            <div className="road__item">
              <div className="road__item-circle"></div>
              <p className="road__item-title">HODLVERSE native wallet release and integration</p>
            </div>
          </section>
        </div>
        <div className="road__section">
          <div className="container road__section-container">
            <div className="road__block-row">
              <div className="road__block-column">
                <p className="road__block-phase">Phase One</p>
                <p className="road__block-title">Network Creation &<br/>Testnet Network Deployment</p>
                <p className="road__block-description">Our goal is to launch HODLVERSE business operations<br/>and the
                  initial minimal viable product on Testnet</p>
              </div>
              <div className="road__block-icon-wrapper">
                <i className="road__block-icon icon-home-phase-1"></i>
              </div>
            </div>
          </div>
          <div className="road__screw">
            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
              <path fill="#f5f7fa" d="M0,0 C50,100 50,100 100,0"/>
            </svg>
          </div>
        </div>
        <div className="road__section road__section_middle">
          <div className="container road__section-container">
            <div className="road__block-icon-wrapper road__block-icon-wrapper_second-phase">
              <i className="road__block-icon icon-home-phase-3"></i>
            </div>
          </div>
        </div>
        <div className="road__section road__section_second">
          <div className="container road__section-container">
            <div className="road__block-row">
              <div className="road__block-column">
                <p className="road__block-phase">Phase Two</p>
                <p className="road__block-title">Mainnet Network Deployment</p>
              </div>
            </div>
          </div>
        </div>
        <div className="road__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="white" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <div className="calculator">
        <div className="container">
          <p className="title title_light calculator__title">Value Calculators</p>
          <p className="subtitle subtitle_light calculator__subtitle">HODLVERSE has provided example calculators for you
            to manage your token value.</p>
        </div>
        <div className="calculator__main-form">

          <CalculatorBlock />

          <div className="calculator__table-form">
            <div className="calculator__table-preview-column">
              <div className="calculator__table-preview-block">
                <p className="calculator__table-preview-title">$MONEY IDO Price</p>
                <p className="calculator__table-preview-value">$0.01</p>
              </div>
              <div className="calculator__table-preview-block">
                <p className="calculator__table-preview-title">Market Cap</p>
                <p className="calculator__table-preview-value">$5M</p>
              </div>
            </div>
            <div className="calculator__table-main-column">
              <div className="calculator__table-main-title-wrapper">
                <p className="calculator__table-main-title">If Market Cap of $MONEY Reaches</p>
              </div>
              <div className="calculator__table">
                <div className="calculator__table-row">
                  <div className="calculator__table-column">
                    <i className="calculator__table-icon icon-competition-uniswap"></i>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">1,210x</p>
                    <p className="calculator__table-value">$6.05</p>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">Market Cap</p>
                    <p className="calculator__table-value">13.2B</p>
                  </div>
                </div>
                <div className="calculator__table-row">
                  <div className="calculator__table-column">
                    <i className="calculator__table-icon icon-pancake-dark"></i>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">420x</p>
                    <p className="calculator__table-value">$2.10</p>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">Market Cap</p>
                    <p className="calculator__table-value">4.6B</p>
                  </div>
                </div>
                <div className="calculator__table-row">
                  <div className="calculator__table-column">
                    <i className="calculator__table-icon icon-sushi"></i>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">420x</p>
                    <p className="calculator__table-value">$2.10</p>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">Market Cap</p>
                    <p className="calculator__table-value">4.6B</p>
                  </div>
                </div>
                <div className="calculator__table-row">
                  <div className="calculator__table-column">
                    <i className="calculator__table-icon icon-axie"></i>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">680x</p>
                    <p className="calculator__table-value">$3.40</p>
                  </div>
                  <div className="calculator__table-column">
                    <p className="calculator__table-title">Market Cap</p>
                    <p className="calculator__table-value">7.5B</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="calculator__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#3e68dd" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <Footer/>
      <SocialFooter/>
    </>
  )
}

export default OfferingPage;