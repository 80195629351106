import {ReactNode} from "react";

interface CheckboxProps {
  label: string | ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({label, checked, onChange}) => {
  return (
    <label className="checkbox">{label}
      <input type="checkbox" checked={checked} onChange={e => onChange(e.target.checked)} />
      <span className="checkmark"></span>
    </label>
  )
}

export default Checkbox;