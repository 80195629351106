import {useRef, useState} from "react";
import Slider from "react-slick";

import {ReactComponent as BackForm} from "../../assets/icons/nft-back.svg";

const nftList = [
  {
    icon: 'icon-bank',
    title: 'Bank',
    subtitle: 'The property and protocol for asset lending and borrowing in the HODLVERSE network.'
  },
  {
    icon: 'icon-insurance-tower',
    title: 'Insurance Tower',
    subtitle: 'The property and protocol for asset insurance in the HODLVERSE network.'
  },
  {icon: 'icon-airplane', title: 'Airplane', subtitle: 'An NFT property with ad rights in HODLVERSE.'},
  {
    icon: 'icon-airport',
    title: 'Airport',
    subtitle: 'The property used to travel to other cities on the network and create or join multiplayer rooms in the HODLVERSE network.'
  },
  {
    icon: 'icon-arena',
    title: 'Arena',
    subtitle: 'The property for conducting trading battles between users in HODLVERSE. Naming rights for advertising only.'
  },
  {
    icon: 'icon-farm-phase-1',
    title: 'Community Farm - Phase 1',
    subtitle: 'The property for the farming of the HVLP token in the HODLVERSE network.'
  },
  {
    icon: 'icon-farm-phase-2',
    title: 'Community Farm - Phase 2',
    subtitle: 'The property for the farming of the HVLP token in the HODLVERSE network.'
  },
  {
    icon: 'icon-farm-phase-3',
    title: 'Community Farm - Phase 3',
    subtitle: 'The property for the farming of the HVLP token in the HODLVERSE network.'
  },
  {
    icon: 'icon-community-mine',
    title: 'Community Mine',
    subtitle: 'The property for the staking of the MONEY token in the HODLVERSE network.'
  },
  {
    icon: 'icon-pool-phase-1',
    title: 'Community Pool - Phase 1',
    subtitle: 'The property for all pools and adding liquidity in the HODLVERSE network.'
  },
  {
    icon: 'icon-pool-phase-2',
    title: 'Community Pool - Phase 2',
    subtitle: 'The property for all pools and adding liquidity in the HODLVERSE network.'
  },
  {
    icon: 'icon-pool-phase-3',
    title: 'Community Pool - Phase 3',
    subtitle: 'The property for all pools and adding liquidity in the HODLVERSE network.'
  },
  {
    icon: 'icon-datacenter',
    title: 'Datacenter',
    subtitle: 'The property for viewing network data and analytics in HODLVERSE.'
  },
  {
    icon: 'icon-factory',
    title: 'Factory',
    subtitle: 'The property for minting or burning digital assets in HODLVERSE.'
  },
  {
    icon: 'icon-gallery',
    title: 'Gallery',
    subtitle: 'The property and marketplace to discover and purchase NFTs in HODLVERSE.'
  },
  {icon: 'icon-square', title: 'HODL Square', subtitle: 'An NFT property with ad rights in HODLVERSE.'},
  {
    icon: 'icon-news-station',
    title: 'News Station',
    subtitle: 'The property for viewing industry and network news in the HODLVERSE network.'
  },
  {
    icon: 'icon-physic-park',
    title: 'Psychic Park',
    subtitle: 'The property and protocol for prediction markets in the HODLVERSE network'
  },
  {
    icon: 'icon-public-storage',
    title: 'Public Storage',
    subtitle: 'The property for locking (liquidity and time) assets in the HODLVERSE network.'
  },
  {
    icon: 'icon-supermarket',
    title: 'Supermarket',
    subtitle: 'The property and marketplace to discover and purchase digital assets using fiat currency in the HODLVERSE network.'
  },
  {
    icon: 'icon-swap-meet',
    title: 'Swap Meet',
    subtitle: 'The property for decentralized digital asset exchange in the HODLVERSE network.'
  },
  {icon: 'icon-townhall', title: 'Townhall', subtitle: 'The property for network governance of the HODLVERSE network.'},
  {
    icon: 'icon-home-phase-1',
    title: 'User Home - Phase 1',
    subtitle: 'The property to view user wallet information when the wallet is connected to the HODLVERSE network.'
  },
  {
    icon: 'icon-home-phase-2',
    title: 'User Home - Phase 2',
    subtitle: 'The property to view user wallet information when the wallet is connected to the HODLVERSE network.'
  },
  {
    icon: 'icon-home-phase-3',
    title: 'User Home - Phase 3',
    subtitle: 'The property to view user wallet information when the wallet is connected to the HODLVERSE network.'
  },
  {
    icon: 'icon-post-office',
    title: 'Post Office',
    subtitle: 'The property for sending assets in bulk using a single transaction in the HODLVERSE network.'
  },
]

const pageSize = 3;

const NFTBlock: React.FC = () => {
  const [activeDotIndex, setActiveDotIndex] = useState(0);

  const slider = useRef<any>();

  const settings = {
    slidesToShow: pageSize,
    slidesToScroll: pageSize,
    dots: false,
    arrows: false,
    centerPadding: "0",
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    // lazyLoad: "progressive" as LazyLoadTypes,
    swipeToSlide: true,
    afterChange: (currentSlide: number) => {
      const pageCount = Math.floor(nftList.length / pageSize);

      let correctDotIndex = Math.ceil(currentSlide / pageSize);
      if (correctDotIndex > pageCount) {
        correctDotIndex = pageCount;
      }

      setActiveDotIndex(correctDotIndex);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="nft">
      <p className="title nft__title">NFTs Will Never Be The Same</p>
      <p className="subtitle nft__subtitle">Purchase and own virtual real estate in HODLVERSE with revenue-generating
        functional NFTs.</p>
      <Slider {...settings} ref={slider} className="nft__slide-list">
        {nftList.map((item, index) => (
          <div key={index}>
            <div className="nft__slide">
              <BackForm/>
              <div className="nft__slide-content-wrapper">
                <div className="nft__slide-icon-wrapper">
                  <i className={`icon nft__slide-icon ${item.icon}`}></i>
                </div>
                <p className="nft__slide-title">{item.title}</p>
                <p className="nft__slide-subtitle">{item.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="container">
        <div className="nft__pagination-block">
          <div className="nft__page-btn nft__page-btn_prev" onClick={() => slider?.current?.slickPrev()}></div>
          <div className="nft__page-line">
            <div className="nft__pages-wrapper">
              {Array.from(Array(8).keys()).map((item, index) => (
                <div key={index}
                     className={`nft__page-circle ${activeDotIndex === index && 'nft__page-circle_active'}`}></div>
              ))}
            </div>
          </div>
          <div className="nft__page-btn nft__page-btn_next" onClick={() => slider?.current?.slickNext()}></div>
        </div>
      </div>
    </div>
  )
}

export default NFTBlock;