import CookieForm from '../components/cookieForm'
import UnsupportedScreenPopup from '../components/unsupportedScreenPopup'

import {ReactNode} from "react";

interface WrapperProps {
  children: ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({children}) => {
  return (
    <>
      {children}
      <CookieForm/>
      <UnsupportedScreenPopup />
    </>
  )
}

export default Wrapper;