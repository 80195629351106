import {useLocation, useNavigate} from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;

  const navigateTo = (link: string) => {
    navigate(link)
  }

  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__info-block">
          <div className="footer__logo"></div>
          <p className="footer__description footer__description_main invisible_mobile">The HODLVERSE is a virtual tokenized world and
            decentralized network that helps citizens manage their digital assets. </p>
        </div>
        <div className="footer__columns-container">
          <div className="footer__column">
            <p className={`footer__link ${path === '/' && 'footer__link_active'}`} onClick={() => navigateTo('/')}>home</p>
            <p className={`footer__link ${path === '/economy' && 'footer__link_active'}`} onClick={() => navigateTo('/economy')}>economy</p>
            <p className={`footer__link ${path === '/offering' && 'footer__link_active'}`} onClick={() => navigateTo('/offering')}>offering</p>
            <a className="footer__link" href="https://hodlverse.gitbook.io/docs" target="_blank" rel="noreferrer">docs</a>
          </div>
          <div className="footer__column">
            <a className="footer__link" href="https://hodlverse.gitbook.io/docs/legal/terms-of-service"
               target="_blank" rel="noreferrer">terms & conditions</a>
            <a className="footer__link" href="https://hodlverse.gitbook.io/docs/legal/privacy-policy" target="_blank" rel="noreferrer">privacy
              policy</a>
            <a className="footer__link" href="https://hodlverse.gitbook.io/docs/legal/disclaimer"
               target="_blank" rel="noreferrer">disclaimer</a>
          </div>
          <div className="footer__column">
            <a className="footer__link" href="https://bit.ly/hodlvalley" target="_blank" rel="noreferrer">discord</a>
            <a className="footer__link" href="https://github.com/hodlvalley" target="_blank" rel="noreferrer">github</a>
            <a className="footer__link" href="https://opensea.io/hodlverse" target="_blank" rel="noreferrer">Opensea</a>
            <a className="footer__link" href="https://linkedin.com/company/hodlverse" target="_blank" rel="noreferrer">linkedin</a>
          </div>
        </div>
        <p className="footer__description invisible_not-mobile">The HODLVERSE is a virtual tokenized world and
          decentralized network that helps citizens manage their digital assets. </p>
      </div>
      <div className="footer__screw">
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
             fill-rule="inherit">
          <path fill="white" d="M0,0 C50,100 50,100 100,0"/>
        </svg>
      </div>
    </footer>
  )
};

export default Footer;