const SocialFooter: React.FC = () => {
  return (
    <div className="social-footer">
      <div className="container social-footer__container">
        <div className="social-footer__social-list">
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://facebook.com/hodlverse" target="_blank" rel="noreferrer">
              <div
                className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_facebook">
                <i className="social-footer__social-item-icon icon-facebook"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="http://www.twitter.com/thehodlverse" target="_blank" rel="noreferrer">
              <div
                className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_twitter">
                <i className="social-footer__social-item-icon icon-twitter"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://t.me/hodlverse" target="_blank" rel="noreferrer">
              <div
                className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_twitter">
                <i className="social-footer__social-item-icon icon-telegram"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://www.reddit.com/r/hodlverse" target="_blank" rel="noreferrer">
              <div className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_reddit">
                <i className="social-footer__social-item-icon icon-reddit"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://medium.com/@hodlverse" target="_blank" rel="noreferrer">
              <div className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_medium">
                <i className="social-footer__social-item-icon icon-medium"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://www.youtube.com/channel/UChO0SYgrPu70J7CtMZCgZ8A"
               target="_blank" rel="noreferrer">
              <div
                className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_youtube">
                <i className="social-footer__social-item-icon icon-youtube"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://bit.ly/hodlverse" target="_blank" rel="noreferrer">
              <div
                className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_discord">
                <i className="social-footer__social-item-icon icon-discord"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://github.com/hodlverse" target="_blank" rel="noreferrer">
              <div className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_medium">
                <i className="social-footer__social-item-icon icon-github"></i>
              </div>
            </a>
          </div>
          <div className="social-footer__social-item-wrapper">
            <a className="social-footer__social-item" href="https://instagram.com/hodlverse" target="_blank" rel="noreferrer">
              <div
                className="social-footer__social-item-icon-wrapper social-footer__social-item-icon-wrapper_instagram">
                <i className="social-footer__social-item-icon icon-instagram"></i>
              </div>
            </a>
          </div>
        </div>
        <div className="social-footer__copyright-block">
          <p className="social-footer__copyright-text">HODLVERSE © 2021 <br className="invisible_mobile"/>All Rights
            Reserved.</p>
        </div>
      </div>
    </div>
  )
};

export default SocialFooter;