const PARAMS = {
	GLOBAL_CONSTANTS: {
		TESTMODE: process.env.REACT_APP_TESTMODE ? JSON.parse(process.env.REACT_APP_TESTMODE) : false,

		VOUCHED_PUBLIC_KEY_SANDBOX: process.env.REACT_APP_VOUCHED_PUBLIC_KEY_SANDBOX || '',
		VOUCHED_PUBLIC_KEY_LIVE: process.env.REACT_APP_VOUCHED_PUBLIC_KEY_LIVE || '',

		RECAPTCHA_SITEKEY_SANDBOX: process.env.REACT_APP_RECAPTCHA_SITEKEY_SANDBOX || '',
		RECAPTCHA_SITEKEY_LIVE: process.env.REACT_APP_RECAPTCHA_SITEKEY_LIVE || ''
	}
};


export default PARAMS;