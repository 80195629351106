import {useCallback, useEffect, useMemo, useState} from "react";
import {useWeb3React} from '@web3-react/core'
import Header from '../components/header'
import Footer from '../components/footer'
import SocialFooter from '../components/socialFooter'

import {ReactComponent as CheckboxIcon} from "../assets/icons/bridge-checkbox.svg";
import {ReactComponent as AvaxIcon} from "../assets/icons/bridge/avax.svg";
import {ReactComponent as MaticIcon} from "../assets/icons/bridge/matic.svg";
import {ReactComponent as BinanceIcon} from "../assets/icons/bridge/binance.svg";
import {ReactComponent as FantomIcon} from "../assets/icons/bridge/fantom.svg";
import {ReactComponent as QuestionIcon} from "../assets/icons/bridge/question.svg";

import BridgeDropdown from "../components/bridgeDropdown";
import {useConnectWallet} from "../hooks/useWallet";
import {useBridgeCallback} from "../hooks/useBridge";
import { ChainId } from "../constants/chainIds";
import { prettyBalance} from "../functions/assets";

type BridgeChain = { name: string, chainId: number }

const chainList: Array<BridgeChain> = [
  { name: 'Ethereum-Ropsten', chainId: ChainId.ROPSTEN, },
  { name: 'Avalanche Testnet', chainId: ChainId.AVALANCHE_TESTNET, },
  { name: 'Binance Smart Chain Testnet', chainId: ChainId.BSC_TESTNET, },
  { name: 'Polygon Testnet', chainId: ChainId.MATIC_TESTNET, },
  { name: 'Fantom Testnet', chainId: ChainId.FANTOM_TESTNET, },
];

const BridgePage: React.FC = () => {
  const { account, chainId } = useWeb3React();

  const {asyncConnect, addCustomChain} = useConnectWallet()


  const [amount, setAmount] = useState('');
  const [converted, setConverted] = useState(false);
  const [fromChain, setFromChain] = useState<BridgeChain>(null);
  const [toChain, setToChain] = useState<BridgeChain>(null);

  const {bridgeTokens, tokenBalance} = useBridgeCallback(toChain?.chainId, amount)

  const walletConnected = useMemo(()=>{
    return !!account;
  }, [account])

  useEffect(()=>{
    const activeFromChainId = chainList.find((chainBridge)=>chainBridge.chainId === chainId)
    setFromChain(activeFromChainId)
  }, [chainId])

  const startBridgingToken = useCallback(()=>{
    if (fromChain?.chainId && fromChain?.chainId && amount.length > 0) {
      bridgeTokens()
    }
  }, [fromChain, toChain, amount]);



  const handleChangeAmount = (e: any) => {
    const val = e.target.value;
    setAmount(val.replace(/[^\d.]/g, ''));
  }

  return (
    <>
      <div className="top-wrapper w-full">
        <Header/>
        <div className="container bridge__container">
          <h1 className="title title_light bridge__title">Use $MONEY on Your Favorite Blockchain</h1>
          <h3 className="subtitle subtitle_light bridge__subtitle">The Money network features bridge smart contracts to
            enable citizens to use the $MONEY token various popular blockchain networks.</h3>
          <div className="bridge__chart-container">
            <i className="bridge__chart-money-token"></i>
            <div className="bridge__chart-token bridge__chart-token_avax">
              <div className="bridge__chart-token-box">
                <i className="bridge__chart-token-icon">
                  <AvaxIcon />
                </i>
              </div>
              <p className="bridge__chart-token-title">Avax</p>
            </div>
            <div className="bridge__chart-token bridge__chart-token_matic">
              <div className="bridge__chart-token-box">
                <i className="bridge__chart-token-icon">
                  <MaticIcon />
                </i>
              </div>
              <p className="bridge__chart-token-title">Matic</p>
            </div>
            <div className="bridge__chart-token bridge__chart-token_binance">
              <div className="bridge__chart-token-box">
                <i className="bridge__chart-token-icon">
                  <BinanceIcon />
                </i>
              </div>
              <p className="bridge__chart-token-title">Binance</p>
            </div>
            <div className="bridge__chart-token bridge__chart-token_fantom">
              <div className="bridge__chart-token-box">
                <i className="bridge__chart-token-icon">
                  <FantomIcon />
                </i>
              </div>
              <p className="bridge__chart-token-title">Fantom</p>
            </div>
            <div className="bridge__chart-token bridge__chart-token_new">
              <div className="bridge__chart-token-box">
                <i className="bridge__chart-token-icon">
                  <QuestionIcon />
                </i>
              </div>
              <p className="bridge__chart-token-title">New</p>
            </div>
          </div>
        </div>
        <div className="statistic__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#3c53d6"
               fill-rule="inherit">
            <path d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <div className="bridge__dapp-section">
        <div className="container bridge__dapp-container">
          <div className="bridge__dapp-top-block">
            <div className="bridge__dapp-top-column">
              <p className="title bridge__dapp-title">Using the <span className="nowrap">Money Bridge</span> DApp</p>
            </div>
            <div className="bridge__dapp-top-column">
              <p className="subtitle bridge__dapp-subtitle">The MONEY Bridge DApp helps you seamlessly convert your
                $MONEY tokens to other blockchain protocols.</p>
              <p className="bridge__dapp-desc">You can also send tokens directly to the smart contract to convert $MONEY
                to another blockchain protocol. Instructions can be found here.</p>
            </div>
          </div>
          <div className="bridge__dapp-form-wrapper">
            <div className="bridge-form">
              <i className="bridge-form__token"></i>
              <div
                className={`bridge-form__block bridge-form__block_wallet ${walletConnected && 'bridge-form__block_active'}`}>
                <div className="bridge-form__block-circle"></div>
                <p className="bridge-form__block-number">1</p>
                <div className="bridge-form__block-info">
                  <p className="bridge-form__block-info-title">Connect Wallet</p>
                  <div className="bridge-form__block-info-action-wrapper">
                    <button className={`bridge-form__btn ${walletConnected && 'bridge-form__btn_success'}`}
                            onClick={asyncConnect}>
                      {walletConnected ? "Connected Wallet" : "Connect Wallet"}
                    </button>
                  </div>
                </div>
                <div className="bridge-form__block-checkbox-wrapper">
                  <CheckboxIcon/>
                </div>
              </div>

              <div className={`bridge-form__block bridge-form__block_token-1 ${!!fromChain && 'bridge-form__block_active'}`}>
                <div className="bridge-form__block-circle"></div>
                <p className="bridge-form__block-number">2</p>
                <div className="bridge-form__block-info">
                  <p className="bridge-form__block-info-title">Select Chain</p>
                  <div className="bridge-form__block-info-action-wrapper">
                    <BridgeDropdown options={chainList.filter(chain => chain.chainId !== toChain?.chainId)} currentItem={fromChain} onChange={(token: BridgeChain) => {
                      addCustomChain(token.chainId)
                    }} />
                    <div className="bridge-form__token-balance-block">
                      <p className="bridge-form__token-balance-value">{prettyBalance(tokenBalance?.toString())}</p>
                      <p className="bridge-form__token-balance-title">Available</p>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="bridge-form__block-checkbox-wrapper">
                  <CheckboxIcon/>
                </div>
              </div>

              <div className={`bridge-form__block bridge-form__block_token-2 ${!!toChain && 'bridge-form__block_active'}`}>
                <div className="bridge-form__block-circle"></div>
                <p className="bridge-form__block-number">3</p>
                <div className="bridge-form__block-info">
                  <p className="bridge-form__block-info-title">Select Chain</p>
                  <div className="bridge-form__block-info-action-wrapper">
                    <BridgeDropdown options={chainList.filter((chain)=>chain.chainId !== fromChain?.chainId)} currentItem={toChain} onChange={token => setToChain(token)} />
                  </div>
                </div>
                <div className="bridge-form__block-checkbox-wrapper">
                  <CheckboxIcon/>
                </div>
              </div>

              <div
                className={`bridge-form__block bridge-form__block_amount ${!!amount && 'bridge-form__block_active'}`}>
                <div className="bridge-form__block-circle"></div>
                <p className="bridge-form__block-number">4</p>
                <div className="bridge-form__block-info">
                  <p className="bridge-form__block-info-title">Enter Amount</p>
                  <div className="bridge-form__block-info-action-wrapper">
                    <input className="bridge-form__input" type="text"
                           onChange={handleChangeAmount}
                           value={amount}
                           placeholder="Enter amount..."/>
                  </div>
                </div>
                <div className="bridge-form__block-checkbox-wrapper">
                  <CheckboxIcon/>
                </div>
              </div>

              <div
                className={`bridge-form__block bridge-form__block_convert ${converted && 'bridge-form__block_active'}`}>
                <div className="bridge-form__block-circle"></div>
                <p className="bridge-form__block-number">5</p>
                <div className="bridge-form__block-info">
                  <p className="bridge-form__block-info-title">Press Convert</p>
                  <div className="bridge-form__block-info-action-wrapper">
                    <button className={`bridge-form__btn ${converted && 'bridge-form__btn_success'}`}
                            disabled={!walletConnected || !amount}
                            onClick={startBridgingToken}>
                      {converted ? 'Converted' : 'Convert'}
                    </button>
                  </div>
                </div>
                <div className="bridge-form__block-checkbox-wrapper">
                  <CheckboxIcon/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
      <SocialFooter/>
    </>
  )
}

export default BridgePage;
