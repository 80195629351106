import {useState, useEffect} from "react";

const HodlversePage: React.FC = () => {
  const [unityLoadProgress, setUnityLoadProgress] = useState(0)

  useEffect(() => {
    const unityLoading = (data: { progress: number, unityInstance: any }) => {
      const {
        progress
      } = data

      const correctProgress = Math.round(progress * 100 || 0)

      if (correctProgress < 100) {
        setUnityLoadProgress(correctProgress)
      } else {
        setTimeout(() => {
          setUnityLoadProgress(100)
        }, 2500)
      }
    }

    const unityLoaderProgress = (e) => {
      unityLoading((e as any).detail)
    }

    window.addEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', unityLoaderProgress, false)

    return () => {
      window.removeEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', unityLoaderProgress);
    }
  }, [])

  return (
    <section className="hodl">
      <div className={`hodl__loader-wrapper ${unityLoadProgress === 100 ? 'hodl__loader-wrapper_hide' : ''}`}>
        <i className="hodl__ribbon" />
        <div className="hodl__loader">
          <div className="hodl__loader-form">
            <div className="hodl__loader-progress" style={{ width: `calc(${unityLoadProgress}% - 4px)` }}>
            </div>
            <p className="hodl__loader-text">loading {unityLoadProgress}%...</p>
          </div>
        </div>
      </div>
      <div
        className={`hodl__unity-game-wrapper ${unityLoadProgress === 100 ? 'hodl__unity-game-wrapper_show' : ''}`}>
        <iframe src="/hodlverse/index.html" title={"HODL"} frameBorder="0" />
      </div>
    </section>
  )
}

export default HodlversePage;