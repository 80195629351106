import Modal from "./modal";
import YouTube, { YouTubeProps } from 'react-youtube';
import {useCallback, useEffect, useState} from "react";

interface YoutubeVideoPopupProps {
  open: boolean;
  onClose: () => void;
}

const YoutubeVideoPopup: React.FC<YoutubeVideoPopupProps> = ({open, onClose}) => {
  const [player, setPlayer] = useState<any>();

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    setPlayer(event.target);
  }

  useEffect(() => {
    open && player && player?.playVideo();
  }, [player, open])

  const handleClose = useCallback(() => {
    onClose();
    player?.pauseVideo();
  }, [player, onClose])

  return (
    <Modal open={open} onClose={handleClose} wrapClassName={'youtube-popup'}>
      <div id="youtube-form-data">
        <YouTube videoId="eDOku815zdE" id="youtube-iframe" className="youtube-popup__frame-wrapper" onReady={onPlayerReady} />
      </div>
    </Modal>
  )
};

export default YoutubeVideoPopup;