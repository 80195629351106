import {useState} from "react";
import {ReactComponent as AvalancheIcon} from "../../assets/icons/partnerships/avalanche.svg";
import {ReactComponent as BinanceChainIcon} from "../../assets/icons/partnerships/binanceChain.svg";
import {ReactComponent as BrahamSystemIcon} from "../../assets/icons/partnerships/bramahSystem.svg";
import {ReactComponent as EthereumIcon} from "../../assets/icons/partnerships/ethereum.svg";
import {ReactComponent as FantomIcon} from "../../assets/icons/partnerships/fantom.svg";
import {ReactComponent as GetBlockIcon} from "../../assets/icons/partnerships/getBlock.svg";
import {ReactComponent as HackenIcon} from "../../assets/icons/partnerships/hacken.svg";
import {ReactComponent as PolygonIcon} from "../../assets/icons/partnerships/polygon.svg";
import {ReactComponent as AWSIcon} from "../../assets/icons/partnerships/AWS.svg";
import {ReactComponent as TallyIcon} from "../../assets/icons/partnerships/tally.svg";
import {ReactComponent as VercelIcon} from "../../assets/icons/partnerships/vercel.svg";
import {ReactComponent as BitcoinIcon} from "../../assets/icons/partnerships/bitcoin.svg";

const PartnerShipBlock: React.FC = () => {
  const [activeItem, setActiveItem] = useState('service');

  const isService = activeItem === 'service';
  const isSecurity = activeItem === 'security';
  const isBlockchain = activeItem === 'blockchain';

  return (
    <div className="partnerships">
      <div className="container">
        <p className="title partnerships__title">If You Want to Go Farther, BUIDL Together</p>
        <p className="subtitle partnerships__subtitle">
          HODLVERSE collaborates with different service providers and networks to bring you the best network to manage
          your digital assets.
        </p>
        <div className="partnerships__tab-list-wrapper">
          <div className="partnerships__switcher-wrapper">
            <div className={`partnerships__switcher-item ${activeItem === 'service' && 'partnerships__switcher-item_active'}`}
              onClick={() => setActiveItem('service')}>Service</div>
            <div className={`partnerships__switcher-item ${activeItem === 'security' && 'partnerships__switcher-item_active'}`}
                 onClick={() => setActiveItem('security')}>Security</div>
            <div className={`partnerships__switcher-item ${activeItem === 'blockchain' && 'partnerships__switcher-item_active'}`}
                 onClick={() => setActiveItem('blockchain')}>Blockchain</div>
          </div>
        </div>
        <div className="partnerships__cards-list">
          <div className="partnerships__cards-row">
            <a className={`partnerships__card partnerships__card_blockchain partnerships__card_avalanche ${isBlockchain && 'partnerships__card_active'}`}
               href="https://www.avax.network/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <AvalancheIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_blockchain partnerships__card_binance-chain ${isBlockchain && 'partnerships__card_active'}`}
               href="https://www.binance.org/en/smartChain" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <BinanceChainIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_security partnerships__card_bramah ${isSecurity && 'partnerships__card_active'}`}
               href="http://bramah.systems" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <BrahamSystemIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_blockchain partnerships__card_ethereum ${isBlockchain && 'partnerships__card_active'}`}
               href="https://ethereum.org/en/foundation/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <EthereumIcon />
              </div>
            </a>
          </div>
          <div className="partnerships__cards-row">
            <a className={`partnerships__card partnerships__card_blockchain partnerships__card_fantom ${isBlockchain && 'partnerships__card_active'}`}
               href="https://fantom.foundation/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <FantomIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_service partnerships__card_getblock ${isService && 'partnerships__card_active'}`}
               href="https://getblock.io/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <GetBlockIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_security partnerships__card_hacken ${isSecurity && 'partnerships__card_active'}`}
               href="https://hacken.io" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <HackenIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_blockchain partnerships__card_polygon ${isBlockchain && 'partnerships__card_active'}`}
               href="https://polygon.technology/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <PolygonIcon />
              </div>
            </a>
          </div>
          <div className="partnerships__cards-row">
            <a className={`partnerships__card partnerships__card_service partnerships__card_aws ${isService && 'partnerships__card_active'}`}
               href="https://aws.amazon.com" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <AWSIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_service partnerships__card_tally ${isService && 'partnerships__card_active'}`}
               href="https://www.withtally.com/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <TallyIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_service partnerships__card_vercel ${isService && 'partnerships__card_active'}`}
               href="https://vercel.com/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <VercelIcon />
              </div>
            </a>
            <a className={`partnerships__card partnerships__card_blockchain partnerships__card_bitcoin ${isBlockchain && 'partnerships__card_active'}`}
               href="https://bitcoin.org/en/" target="_blank" rel="noreferrer">
              <div className="partnerships__card-icon">
                <BitcoinIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="partnerships__screw">
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="#fff"
             fill-rule="inherit">
          <path d="M0,0 C50,100 50,100 100,0"/>
        </svg>
      </div>
    </div>
  )
}

export default PartnerShipBlock;