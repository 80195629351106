import {useWeb3React} from "@web3-react/core";
import {useMemo} from "react";
import { Contract } from '@ethersproject/contracts'
import { getContract } from "../functions/contracts";
import { BRIDGE_ADDRESS_MAP } from "../constants/addresses";
import SideBridgeABI from '../constants/abis/SideBridge'
import MainBridgeABI from '../constants/abis/MainBridge'
import ERC20ABI from '../constants/abis/ERC20'
import {ChainId} from "../constants/chainIds";

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {

    const { library, account } = useWeb3React();

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, withSignerIfPossible, account])
}

export function useMainBridgeContract(sideChainId: ChainId, withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useWeb3React();
    return useContract(chainId && sideChainId && BRIDGE_ADDRESS_MAP[sideChainId].MAIN_BRIDGE_ADDRESS, MainBridgeABI, withSignerIfPossible)
}

export function useSideBridgeContract(sideChainId: ChainId, withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useWeb3React();
    return useContract(chainId && sideChainId && BRIDGE_ADDRESS_MAP[sideChainId].SIDE_BRIDGE_ADDRESS, SideBridgeABI, withSignerIfPossible)
}


export function useErc20Contract(address: string, withSignerIfPossible?: boolean){
    return useContract(address, ERC20ABI, withSignerIfPossible)
}
