import Slider from "react-slick";

import {useRef} from "react";

const elList = [
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>No Insider<br/>Pre-sales<br/>Offering</>,
    description: 'The initial decentralized offering will be the ONLY offering available and is restricted to the general public on a first-come first-served basis thereby eliminating pump and dump opportunities on its day of listing.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Simple and Innovative Concept</>,
    description: 'HODLVERSE has merged the industry’s largest trends to disrupt the market by providing a fun and easy way to manage digital assets.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Live Operational Network</>,
    description: 'The HODLVERSE network and platform are a result of significant planning and months of execution. The network has been audited and is live on the blockchain.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Viable Business Model</>,
    description: 'The HODLVERSE network has multiple revenue generating streams and a proven business model that has already begun to generate revenue for its token holders.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Revenue and Reward Sharing</>,
    description: 'Network operators and $MONEY holders all earn fee revenue'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Perpetual Token Rewards</>,
    description: '$MONEY holders earn airdropped token rewards when $MONEY is minted on other chains'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>High Potential</>,
    description: 'Following the success of other peers in the industry such as Uniswap, Sushiswap, Pancakeswap, etc.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Low Market Cap</>,
    description: 'The starting market capitalization of HODLVERSE is $3M. HODLVERSE has the potential to gain a market capitalization that is comparable to or exceeds its peers.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Strong Community</>,
    description: 'HODLVERSE citizens have increased in double digits month-over-month with the help of global attention from various press outlets across the industry.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Time-Locked and Audited Smart Contracts</>,
    description: 'All non-circulating tokens are time-locked and all smart contracts have been audited by a 3rd party security firm.'
  },
  {
    icon: 'icon-square',
    preTitle: 'Why HODLVERSE?',
    title: <>Superior Documentation</>,
    description: 'HODLVERSE has industry leading documentation to aid its community in utilizing and growing the network.'
  },
]

const HeaderScrollBlock: React.FC = () => {
  const slider = useRef<any>();

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1500,
    // lazyLoad: true,
    swipeToSlide: true,
    arrows: false,
    afterChange: (currentSlide: number) => {
      // const pageCount = Math.floor(nftList.length / pageSize);
      //
      // let correctDotIndex = Math.ceil(currentSlide / pageSize);
      // if (correctDotIndex > pageCount) {
      //   correctDotIndex = pageCount;
      // }
      //
      // setActiveDotIndex(correctDotIndex);
    },
  };

  return (
    <div className="offering-head">
      <Slider {...settings} ref={slider} className="offering-head__slide-list">
        {elList.map((item, index) => (
          <div key={index}>
            <div className="offering-head__slide">
              <div className="offering-head__info-box">
                <p className="offering-head__preff-title">{item.preTitle}</p>
                <p className="offering-head__title">{item.title}</p>
                <div className="offering-head__icon-wrapper invisible_not-mobile">
                  <i className={`offering-head__icon ${item.icon}`}></i>
                </div>
                <p className="offering-head__description">
                  {item.description}
                </p>
              </div>
              <div className="offering-head__icon-wrapper offering-head__icon-wrapper_mobile invisible_mobile">
                <i className={`offering-head__icon ${item.icon}`}></i>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="container">
        <div className="nft__pagination-block offering-head__pagination-block">
          <div className="nft__page-btn nft__page-btn_prev" onClick={() => slider?.current?.slickPrev()}></div>
          {/*<div class="nft__page-line">*/}
          {/*  <div class="nft__pages-wrapper">*/}
          {/*    <div class="nft__page-circle nft__page-circle_active" id="offering-header__page-circle-id-1"></div>*/}
          {/*    <div class="nft__page-circle" id="offering-header__page-circle-id-2"></div>*/}
          {/*    <div class="nft__page-circle" id="offering-header__page-circle-id-3"></div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="nft__page-btn nft__page-btn_next" onClick={() => slider?.current?.slickNext()}></div>
        </div>
      </div>
    </div>
  )
}

export default HeaderScrollBlock;