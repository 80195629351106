import {useState} from "react";
import { validateEmail } from "../utils/helper"

interface SubscriptionFormprops {
  onClose: () => void;
}

const SubscriptionForm: React.FC<SubscriptionFormprops> = ({onClose}) => {
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [emailError, setEmailError] = useState('');

  const submitEmail = (e: any) => {
    e.preventDefault();

    if (validateEmail(email)) {
      // @ts-ignore
      _scq.push(["identify", {
        email,
        tags: ["Hodlverse Contacts - 2022-01-27"],
        success: () => {
          console.log("identify call successful");
        },
        failure: () => {
          console.log("identify failed");
        }
      }]);

      setEmailSubmitted(true);
      setEmail('');

      setTimeout(() => {
        setEmailSubmitted(false);
        onClose();
      }, 1500);

    } else {
      setEmailError('Invalid email. Please try again...');

      setTimeout(() => {
        setEmailError('');
      }, 1500);
    }
  }

  return (
    <div className="popup__input-form-wrapper">
      <form id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className={`validate popup__input-wrapper ${emailError && 'error'}`}
            autoComplete="off"
            onSubmit={submitEmail}
            noValidate>
        <input type="email" name="EMAIL"
               className={`popup__input`}
               placeholder="Enter email address..."
               value={emailError || email}
               readOnly={!!emailError}
               onChange={e => setEmail(e.target.value)} />
        <input type="submit"
               value={emailSubmitted ? 'sent' : emailError ? 'error' : 'submit'}
               name="subscribe"
               className={`popup__button ${emailSubmitted && 'submit'}`} />
      </form>
    </div>
  )
};

export default SubscriptionForm;