import Modal from "./modal";

interface KYCPopupProps {
  open: boolean;
}

const KYCPopup: React.FC<KYCPopupProps> = ({open}) => {
  return (
    <Modal open={open}>
      <div id="vouched-form-data" className="popup__vouched-form-data">
        <div id="vouched-element"></div>
      </div>
    </Modal>
  )
};

export default KYCPopup;