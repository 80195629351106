import BigNumber from 'bignumber.js'
const VALUE_DECIMALS = 6

export const unitToCurrency = (decimals: number, value: number | BigNumber | string): BigNumber => {
    const multiplier = new BigNumber(10).pow(decimals)
    return new BigNumber(value).dividedBy(multiplier)
}
export const currencyToUnit = (decimals: number, value: number | BigNumber | string):BigNumber  => {
    const multiplier = new BigNumber(10).pow(decimals)
    return new BigNumber(value).times(multiplier)
}


export const dpUI = (amount, dp = VALUE_DECIMALS) => {
    if (!amount) return amount

    return new BigNumber(amount).dp(dp, BigNumber.ROUND_FLOOR)
}
export const prettyBalance = (amount, dp = VALUE_DECIMALS): string => {
    if (!amount ) return amount

    amount = unitToCurrency(18, amount)

    return dpUI(amount, dp).toString()
}


