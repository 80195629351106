import {useMemo, useState} from "react";

const PropertiesBlock: React.FC = () => {
  const [propertyNumber, setPropertyNumber] = useState(0);

  const propertyList = useMemo(() => {
    return [
      { mainIconClass: 'properties__icon_hodl-valley icon-hodl-valley-building' },
      { mainIconClass: 'properties__icon_hodl-zug icon-hodl-zug-building' },
      { mainIconClass: 'properties__icon_hodl-kong icon-hodl-kong-building' },
      { mainIconClass: 'properties__icon_hodl-island icon-hodl-island-building' },
      { mainIconClass: 'properties__icon_hodl-si icon-hodl-si-building' },
      { mainIconClass: 'properties__icon_hodl-delhi icon-hodl-delhi-building' },
    ]
  }, []);

  const selectProperty = (offset: number) => {
    let currentPosition = propertyNumber + offset;
    const count = propertyList.length;

    if (currentPosition > count - 1) {
      currentPosition = 0
    }
    if (currentPosition < 0) {
      currentPosition = count - 1;
    }

    setPropertyNumber(currentPosition)
  }

  return (
    <div className="properties">
      <div className="container">
        <div className="properties__row">
          <div className="properties__column properties__column_main">
            <div className="properties__main-icon-wrapper" id="properties__main-icon-list-id">
              {propertyList.map((option, index) => (
                <i className={`properties__icon ${option.mainIconClass} ${index === propertyNumber && 'properties__icon_active'}`} key={index}></i>
              ))}
            </div>
            <div className="properties__arrow-list">
              <div className="nft__page-btn nft__page-btn_prev" id="properties__arrow-prev-id" onClick={() => selectProperty(-1)}></div>
              <div className="nft__page-btn nft__page-btn_next" id="properties__arrow-next-id" onClick={() => selectProperty(1)}></div>
            </div>
          </div>
          <div className="properties__column properties__column_extra" id="properties__options-list-id">
            <div className="properties__option-row">
              <div className={`properties__option ${propertyNumber === 0 && 'properties__option_active'}`}>
                <div className="properties__option-block">
                  <i className="properties__option-icon icon-hodl-valley"></i>
                </div>
                <p className="properties__option-text">Bitcoin</p>
              </div>
              <div className={`properties__option ${propertyNumber === 1 && 'properties__option_active'}`}>
                <div className="properties__option-block">
                  <i className="properties__option-icon icon-hodl-zug"></i>
                </div>
                <p className="properties__option-text">Ethereum<br/>ERC20</p>
              </div>
            </div>
            <div className="properties__option-row">
              <div className={`properties__option ${propertyNumber === 2 && 'properties__option_active'}`}>
                <div className="properties__option-block">
                  <i className="properties__option-icon icon-hodl-kong"></i>
                </div>
                <p className="properties__option-text">Binance Smart Chain<br/>BEP20</p>
              </div>
              <div className={`properties__option ${propertyNumber === 3 && 'properties__option_active'}`}>
                <div className="properties__option-block">
                  <i className="properties__option-icon icon-hodl-island"></i>
                </div>
                <p className="properties__option-text">Avalanche<br/>AVAX</p>
              </div>
            </div>
            <div className="properties__option-row">
              <div className={`properties__option ${propertyNumber === 4 && 'properties__option_active'}`}>
                <div className="properties__option-block">
                  <i className="properties__option-icon icon-hodl-si"></i>
                </div>
                <p className="properties__option-text">Fantom<br/>FTM</p>
              </div>
              <div className={`properties__option ${propertyNumber === 5 && 'properties__option_active'}`}>
                <div className="properties__option-block">
                  <i className="properties__option-icon icon-hodl-delhi"></i>
                </div>
                <p className="properties__option-text">Polygon<br/>Matic</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertiesBlock;