import {injected} from "../connectors";
import {useCallback, useMemo} from "react";
import {UnsupportedChainIdError, useWeb3React} from '@web3-react/core'
import {CUSTOM_CHAINS} from "../constants/chains";
import {ChainId} from "../constants/chainIds";

export function useConnectWallet() {
    const { activate, library, } = useWeb3React()

    const addCustomChain = useCallback(async (chainId: ChainId)=>{
        if (library && library.provider.isMetaMask && library.provider.request) {
            if (chainId === ChainId.ROPSTEN || chainId === ChainId.MAINNET) {
                await library.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: CUSTOM_CHAINS[chainId].chainId}],
                })
            } else {
                await library.provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [CUSTOM_CHAINS[chainId]],
                })
            }

        }
    }, [library])

    const asyncConnect = useCallback(async ()=>{
        const connector = injected
        const conn = connector
        activate(conn, undefined, true).catch((error) => {
            if (error instanceof UnsupportedChainIdError) {
                console.log(error)
                alert('Chain not supported')
                activate(conn)
            }
        })
    }, [activate])

    return useMemo(()=>{
        return {
            asyncConnect,
            addCustomChain
        }
    }, [asyncConnect, addCustomChain])
}
