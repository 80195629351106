import {ReactNode} from "react";
import {ReactComponent as PopupFormBackIcon} from "../assets/icons/popup-form-back.svg";

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  wrapClassName?: string;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({open, onClose, wrapClassName, children}) => {
  return (
    <div className={`popup__wrapper ${open && 'popup__wrapper_show'}`}>
      <div className={`popup ${wrapClassName ? wrapClassName : ''}`}>
        {onClose &&
          <div className="popup__close-icon-wrapper" onClick={onClose}></div>
        }
        <PopupFormBackIcon />
        <div className="popup__form">
          {children}
        </div>
      </div>
    </div>
  )
};

export default Modal;