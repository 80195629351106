import Header from '../../components/header'
import Footer from '../../components/footer'
import SocialFooter from '../../components/socialFooter'
import HeaderScrollBlock from "./HeaderScrollBlock";

import {ReactComponent as MetaverseCardWrapper} from "../../assets/icons/metaverse-card-wrapper.svg";
import {ReactComponent as MetaverseCardWrapperMobile} from "../../assets/icons/metaverse-card-wrapper-mobile.svg";

import {ReactComponent as DemandDescriptionBox} from "../../assets/icons/demandDescriptionBox.svg";
import {ReactComponent as DemandDescriptionBoxMobile} from "../../assets/icons/demandDescriptionBoxMobile.svg";

const EconomyPage: React.FC = () => {
  return (
    <>
      <div className="top-wrapper w-full">
        <Header/>

        <HeaderScrollBlock />

        <div className="economy-head__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#3c53d6" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <div className="demand">
        <div className="container">
          <p className="title demand__title">A Continuous Cycle of Value and Demand</p>
          <p className="subtitle demand__subtitle">An adaptive monetary policy with a fixed supply of tokens that aligns
            with
            market trends and demand.</p>
          <div className="demand__icon-wrapper">
            <i className="demand__icon icon-demand"></i>
          </div>
          <div className="demand__description-box">
            <DemandDescriptionBoxMobile className={'demand__item-svg-wrapper invisible_not-mobile'} />
            <DemandDescriptionBox className={'demand__item-svg-wrapper demand__item-svg-wrapper_mobile invisible_mobile'} />
            <div className="demand__description-inner-box">
              <p className="demand__description-text">
                The HODLVERSE is powered by a multi-purposed utility token with a deflationary economic model.<br/>
                The MONEY token has a fixed supply. Network revenue is used for a token buybacks that reward network
                operators and participants.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="operator">
        <div className="operator__screw operator__screw_top">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#f5f7fa" d="M0,100 C50,0 50,0 100,100"/>
          </svg>
        </div>

        <div className="container">
          <div className="operator__main-container">
            <div className="operator__info-block">
              <p className="title operator__title">A Perpetual Rewards<br/>Model for Network<br/>Operators</p>
              <p className="subtitle operator__subtitle">An adaptive monetary policy with a fixed supply of tokens that
                aligns
                with market trends and demand.</p>
            </div>
            <div className="operator__list-info-row">
              <div className="operator__info-item">
                <p className="operator__info-item-number">1</p>
                <p className="operator__info-item-title">Swap Fees</p>
                <p className="operator__info-item-description">.4% fee on all Platform Swaps</p>
              </div>
              <div className="operator__info-item">
                <p className="operator__info-item-number">2</p>
                <p className="operator__info-item-title">Reward</p>
                <p className="operator__info-item-description">.25% of Swap Fees are deposited back in to the Liquidity
                  Pool used
                  for the swap</p>
              </div>
            </div>
            <div className="operator__list-info-row operator__list-info-row_second">
              <div className="operator__info-item operator__info-item_double-name">
                <p className="operator__info-item-number">1</p>
                <p className="operator__info-item-title">Platform<br/>Revenue</p>
                <p className="operator__info-item-description">100% of the variable fees from:</p>
                <div className="operator__info-item-benefits">
                  <p className="operator__info-item-benefit">Display Ads</p>
                  <p className="operator__info-item-benefit">NFT Auctions</p>
                  <p className="operator__info-item-benefit">In-Game Purchases</p>
                </div>
              </div>
              <div className="operator__info-item">
                <p className="operator__info-item-number">2</p>
                <p className="operator__info-item-title">Buyback</p>
                <p className="operator__info-item-description">.15% of Swap Fees and 100% of Platform Revenue are
                  deposited in to
                  the Buyback Contract which purchases money from the MONEY / ETH Liquidity Pool</p>
              </div>
            </div>
            <div className="operator__list-info-row operator__list-info-row_third">
              <div className="operator__info-item">
                <p className="operator__info-item-number">3</p>
                <p className="operator__info-item-title">Replenish</p>
                <p className="operator__info-item-description">MONEY Token Buyback are deposited in to The Reserve
                  Contract</p>
              </div>
              <div className="operator__info-item">
                <p className="operator__info-item-number">4</p>
                <p className="operator__info-item-title">Reward</p>
                <p className="operator__info-item-description">MONEY Tokens in The Reserve Contract are Distributed:</p>
                <div className="operator__info-item-benefits">
                  <p className="operator__info-item-benefit">30% to HVLP Token Holders</p>
                  <p className="operator__info-item-benefit">30% to HVLP Farmers</p>
                  <p className="operator__info-item-benefit">30% to MONEY Stakers</p>
                  <p className="operator__info-item-benefit">10% to The HODLVERSE DAO</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="operator__screw operator__screw_bottom">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="#f5f7fa" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>
      <div className="metaverse">
        <div className="container">
          <p className="title metaverse__title">Fuel for the Modern Metaverse</p>
          <p className="subtitle metaverse__subtitle">HODLVERSE’s network tokens are the fuel to power the HODLVERSE
            ecosystem.</p>
        </div>
        <div className="metaverse__list">
          <div className="metaverse__item">
            <MetaverseCardWrapper className="metaverse__item-svg-wrapper invisible_mobile_tablet" />
            <MetaverseCardWrapperMobile className="metaverse__item-svg-wrapper invisible_not-mobile_tablet" />

            <div className="metaverse__item-form">
              <div className="metaverse__item-column">
                <p className="metaverse__item-number">01.</p>
                <p className="metaverse__item-title">Money<br/>Token</p>
              </div>
              <div className="metaverse__item-token-wrapper metaverse__item-token-wrapper_red"></div>
              <div className="metaverse__item-column">
                <p className="metaverse__item-text">Core to the HOLDVERSE ecosystem is the MONEY Token; A Digital Asset,
                  Network
                  Access Token, and Store of Value that enables citizens to:</p>
                <div className="metaverse__item-benefit-list">
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_first">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Execute HODLVERSE<br/>Smart Contract Code
                  </div>
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_second">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Stake and earn<br/>interest rewards
                  </div>
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_third">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Obtain fee<br/>discounts
                  </div>
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_first">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Create Network Liquidity<br/>and earn interest rewards
                  </div>
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_second">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Exercise<br/>governance rights
                  </div>
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_third">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Make in-app<br/>purchases
                  </div>
                </div>
                <p className="metaverse__item-text">The Token also represents a share in the HODLVERSE and is the
                  voting currency for all proposed protocol and platform changes on the network.</p>
              </div>
            </div>
          </div>
          <div className="metaverse__item">
            <MetaverseCardWrapper className="metaverse__item-svg-wrapper invisible_mobile_tablet" />
            <MetaverseCardWrapperMobile className="metaverse__item-svg-wrapper invisible_not-mobile_tablet" />

            <div className="metaverse__item-form">
              <div className="metaverse__item-column">
                <p className="metaverse__item-number">02.</p>
                <p className="metaverse__item-title">HVLP<br/>Token</p>
              </div>
              <div className="metaverse__item-token-wrapper metaverse__item-token-wrapper_yellow"></div>
              <div className="metaverse__item-column metaverse__item-column_hvlp">
                <p className="metaverse__item-text">The HVLP Token is a Digital Asset, Network Access Token, and Store
                  of Value
                  that enables citizens to:</p>
                <div className="metaverse__item-benefit-list metaverse__item-benefit-list_hvlp">
                  <div
                    className="metaverse__item-benefit-item metaverse__item-benefit-item_full metaverse__item-benefit-item_hvlp">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Create network liquidity and earn liquidity provider rewards
                  </div>
                  <div className="metaverse__item-benefit-item metaverse__item-benefit-item_full">
                    <div className="metaverse__item-benefit-item-circle"></div>
                    Track liquidity created or deposited on the network
                  </div>
                </div>
                <p className="metaverse__item-text">When a citizen adds liquidity to the network, they will receive HVLP
                  Tokens.<br/>
                    For example, if a user deposits MONEY and ETH into a pool, they would receive
                    MONEY-ETH HVLP Token. These tokens represent a proportional share of the
                    pooled assets, allowing a user to reclaim their deposited funds from the pool.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="decentralize__screw">
          <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill-rule="inherit">
            <path fill="white" d="M0,0 C50,100 50,100 100,0"/>
          </svg>
        </div>
      </div>

      <Footer/>
      <SocialFooter/>
    </>
  )
}

export default EconomyPage;