import {useState} from "react";

const FarmBlock: React.FC = () => {
  const [numState, setNumState] = useState(0);

  return (
    <div className="farm">
      <div className="farm__screw">
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" fill="red"
             fill-rule="inherit">
          <path fill="#3c53d6" d="M0,100 C50,0 50,0 100,100"/>
        </svg>
      </div>
      <div className="container">
        <p className="title title_light farm__title">An Experience That Evolves Alongside You</p>
        <p className="subtitle subtitle_light farm__subtitle">Properties are upgraded as you reach individual
          achievements In your overall financial
          journey.</p>
        <div className="farm__info-row">
          <div className="farm__icon-block-wrapper">
            <div className={`farm__icon-wrapper farm__icon-wrapper_1 ${numState === 0 && 'farm__icon-wrapper_show'}`}>
              <i className="farm__icon icon-farm-phase-1"></i>
            </div>
            <div className={`farm__icon-wrapper farm__icon-wrapper_2 ${numState === 1 && 'farm__icon-wrapper_show'}`}>
              <i className="farm__icon icon-farm-phase-2"></i>
            </div>
            <div className={`farm__icon-wrapper farm__icon-wrapper_3 ${numState === 2 && 'farm__icon-wrapper_show'}`}>
              <i className="farm__icon icon-farm-phase-3"></i>
            </div>
          </div>
          <div className="farm__switcher-section">
            <p className="farm__farm-title">Community Farm</p>
            <p className="farm__farm-description">The property for the farming of the HVLP token in the HODLVERSE
              network.</p>
            <div className="farm__switcher-wrapper">
              <div className={`farm__switcher-item ${numState === 0 && 'farm__switcher-item_active'}`} onClick={() => setNumState(0)}>one</div>
              <div className={`farm__switcher-item ${numState === 1 && 'farm__switcher-item_active'}`} onClick={() => setNumState(1)}>two</div>
              <div className={`farm__switcher-item ${numState === 2 && 'farm__switcher-item_active'}`} onClick={() => setNumState(2)}>three</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FarmBlock;