import {useEffect, useState} from "react";

const CookieForm: React.FC = () => {
  const [showForm, setShowForm] = useState(false);

  const setCookie = (name: string, value: string, days: number) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const toggleCookieForm = (isShow: boolean) => {
    setShowForm(isShow);
  }

  const declineCookieBtn = document.getElementById('cookie-decline-id');
  if (declineCookieBtn) {
    declineCookieBtn.addEventListener("click", () => {
      setCookie("is-accepted", "false", 365);
      toggleCookieForm(false);
    });
  }

  const acceptCookieBtn = document.getElementById('cookie-accept-id');
  if (acceptCookieBtn) {
    acceptCookieBtn.addEventListener("click", () => {
      setCookie('is-accepted', 'true', 365);
      toggleCookieForm(false);
    });
  }

  useEffect(() => {
    const isAcceptedVal = getCookie('is-accepted');
    if (!isAcceptedVal) {
      toggleCookieForm(true);
    } else {
      const isAccepted = JSON.parse(isAcceptedVal);
      toggleCookieForm(!isAccepted);
    }
  }, [])

  return (
    <div className={`cookie-form ${showForm ? 'cookie-form_show' : 'cookie-form_hide'}`}>
      <div className="container cookie-form__container">
        <p className="cookie-form__text">We use cookies to make your experience better. <a
          className="cookie-form__text_link" href="https://hodlverse.gitbook.io/docs/legal/cookies-policy"
          target="_blank" rel="noreferrer">Read more</a>.</p>
        <div className="cookie-form__manage-list">
          <button className="button button_orange-note cookie-form__btn" id="cookie-decline-id">decline</button>
          <button className="button button_orange cookie-form__btn" id="cookie-accept-id">accept</button>
        </div>
      </div>
    </div>
  )
};

export default CookieForm;