import { ChainId } from "./chainIds";

type AddressMap = { [chainId: number]: string }

type BridgeContractAddressMap = {[chainId: number]: any}

export const MONEY_ADDRESS: AddressMap = {
    [ChainId.ROPSTEN]: '0x71BCD73877625453A72dEcc60A2108ABF7Eb1a42',
    [ChainId.AVALANCHE_TESTNET]: '0x21ca630f4BFa9079526a7e940752154eA89D9498',
    [ChainId.MATIC_TESTNET]: '0x458d8982d1662c0De42F953fa2b2D4624d141a3c',
    [ChainId.FANTOM_TESTNET]: '0x458d8982d1662c0De42F953fa2b2D4624d141a3c',
    [ChainId.BSC_TESTNET]: '0x10D25a596f4B7CA57869A6f6ccCbd5975847aC4C',
}




export const BRIDGE_ADDRESS_MAP:BridgeContractAddressMap = {
    [ChainId.AVALANCHE_TESTNET]: {
        MAIN_BRIDGE_ADDRESS:'0x168297487e61c8eb36aD84bCD2df1e4d763B205C',
        SIDE_BRIDGE_ADDRESS:'0x458d8982d1662c0De42F953fa2b2D4624d141a3c'
    },
    [ChainId.MATIC_TESTNET]: {
        MAIN_BRIDGE_ADDRESS:'0x5ED518cd122883143a58b39CEa50eeFeA1F001CF',
        SIDE_BRIDGE_ADDRESS:'0xBbD9D3b50BF58DaD8c20E635a9737dD5a80367e2'
    },
    [ChainId.FANTOM_TESTNET]: {
        MAIN_BRIDGE_ADDRESS:'0xbF60213308eB0a0cDDD34Ad901402195B7CF0431',
        SIDE_BRIDGE_ADDRESS:'0xBbD9D3b50BF58DaD8c20E635a9737dD5a80367e2'
    },
    [ChainId.BSC_TESTNET]: {
        MAIN_BRIDGE_ADDRESS:'0x1D9678e1F444b09baf86Ef2A8B60a0D69EF3D35E',
        SIDE_BRIDGE_ADDRESS:'0x3217Ec2BC455AD1bF1EDCDAd28177a35D9062816'
    },
}
