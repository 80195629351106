import {ChainId} from "./chainIds";
import {RPC} from '../connectors'

type ChainsMap = { [chainId: number]: any }

export const CUSTOM_CHAINS: ChainsMap = {
    [ChainId.ROPSTEN]: {
        chainId: `0x3`,
        chainName: 'Ropsten Test Network ',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: [RPC[ChainId.ROPSTEN]],
        blockExplorerUrls: ['https://ropsten.etherscan.io/']
    },
    [ChainId.AVALANCHE_TESTNET]: {
        chainId: `0xA869`,
        chainName: 'Avalanche Network Testnet',
        nativeCurrency: {
            name: 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        rpcUrls: [RPC[ChainId.AVALANCHE_TESTNET]],
        blockExplorerUrls: ['https://explorer.avax-test.network/']
    },
    [ChainId.MATIC_TESTNET]: {
        chainId: `0x13881`,
        chainName: 'Polygon',
        nativeCurrency: {
            name: 'Polygon',
            symbol: 'MATIC',
            decimals: 18
        },
        rpcUrls: [RPC[ChainId.MATIC_TESTNET]],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/']
    },
    [ChainId.FANTOM_TESTNET]: {
        chainId: `0xfa2`,
        chainName: 'Fantom testnet',
        nativeCurrency: {
            name: 'Fantom',
            symbol: 'FTM',
            decimals: 18
        },
        rpcUrls: [RPC[ChainId.FANTOM_TESTNET]],
        blockExplorerUrls: ['https://testnet.ftmscan.com/']
    },
    [ChainId.BSC_TESTNET]: {
        chainId: `0x61`,
        chainName: 'Smart Chain - Testnet',
        nativeCurrency: {
            name: 'Binance Smart Chain',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: [RPC[ChainId.BSC_TESTNET]],
        blockExplorerUrls: ['https://testnet.bscscan.com']
    },
}

