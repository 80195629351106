export enum ChainId {
    MAINNET = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GÖRLI = 5,
    KOVAN = 42,
    MATIC = 137,
    MATIC_TESTNET = 80001,
    FANTOM = 250,
    FANTOM_TESTNET = 4002,
    XDAI = 100,
    BSC = 56,
    BSC_TESTNET = 97,
    ARBITRUM = 42161,
    ARBITRUM_TESTNET = 79377087078960,
    MOONBEAM_TESTNET = 1287,
    AVALANCHE = 43114,
    AVALANCHE_TESTNET = 43113,
    HECO = 128,
    HECO_TESTNET = 256,
    HARMONY = 1666600000,
    HARMONY_TESTNET = 1666700000,
    OKEX = 66,
    OKEX_TESTNET = 65,
    CELO = 42220,
    PALM = 11297108109,
    PALM_TESTNET = 11297108099
}
